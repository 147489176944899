import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import '../../css/game/private.nft.css'
import ImgS3 from "../../components/ImgS3";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function HumanP() {
  const [sellHuman, setSellHuman] = useState(0)
  const [sellRocket, setSellRocket] = useState(0)
  const [listH, setListH] = useState([]);
  const [listR, setListR] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const presaleH = [
    {
      id: 1,
      name: "ASTRONAUT 1",
      usage: 70,
      href: "#",
      box: 3500,
      imageSrc: "/images/private/box1.png",
      imageAlt: "Box",
      price: "16$",
      level: "Robot",
    },
    {
      id: 2,
      name: "ASTRONAUT 2",
      usage: 75,
      href: "#",
      box: 3500,
      imageSrc: "/images/private/box2.png",
      imageAlt: "Box",
      price: "40$",
      level: "Chief",
    },
    {
      id: 3,
      name: "ASTRONAUT 3",
      usage: 80,
      href: "#",
      box: 3000,
      imageSrc: "/images/private/box3.png",
      imageAlt: "Box",
      price: "150$",
      level: "Engineer",
    },
    {
      id: 4,
      name: "ASTRONAUT 4",
      usage: 90,
      href: "#",
      box: 2600,
      imageSrc: "/images/private/box4.png",
      imageAlt: "Box",
      price: "200$",
      level: "Academic",
    },
    {
      id: 5,
      name: "ASTRONAUT 5",
      usage: 90,
      href: "#",
      box: 1800,
      imageSrc: "/images/private/box5.png",
      imageAlt: "Front of men's Basic Tee in black.",
      price: "260$",
      level: "Admin",
      remainingFee: 0
    },
  ];


  useEffect(() => {
    setListH(presaleH);
  }, [listH]);


  const handleClick = (e) => {
    const { id, checked, } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <>
      <div className="mx-10vw">
        <div className="mx-auto max-w-6xl mt-10 ">
        <div className="home-section mystery-content">
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <div className="section-tlt">
                <center style={{alignContent: 'center !important',alignItems: 'center'}} className="tlt text-4xl mb-4">Human Boxes</center>
                <p className="para">When you buy in the presale, you get more uses of NFTs and also you get a higher percentage of probability that you get a NFT with the maximum experience. Remember that to reach planet 1 we need 150 experience.</p>
              </div>
            </div>
          </div>
          <div className="row boxs-ls">
            <div className="w-full pt-10 z-10">
              <center>
                {listH.map((human,index) => {
                  return (
                    <div index={index} key={index} className="inline-block w-full md:w-1/2 my-10 z-9999 text-center px-2 py-2 text-white z-10">
                    <div className="flex flex-col items-center justify-center mx-auto bg-color2 rounded-2xl mx-2 shadow-lg" style={{maxWidth: '320px'}}>
                      <ImgS3 src={human.imageSrc} alt="Contains 3 random NFTs" className="h-80 object-contain" />
                      <p className="text-primary mb-1 mr-4 px-6 -mt-1 py-1 bg-gray-700 rounded-full">{human.box}/{human.box}</p>
                      <div className="w-full p-4">
                        <div className="flex flex-col items-start justify-center bg-color1 p-5 rounded-2xl w-full text-left">
                          <span className="my-1">Human Box level {human.id}</span><span className="my-1 text-colorGreen">{human.price}$<span className="ml-2 text-white">(BUSD)</span></span>
                          <div>
                          <input
                                type="number"
                                name="number"
                                id="number"
                                min="1"
                                max="9"
                                required
                                
                                onChange={handleClick}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-800 mb-3 px-4"
                                placeholder="0"
                              />
                            </div>
                          
                          <div className="flex justify-center items-center w-full mt-3">
                            <button className="bn632-hover bn25 btn7"><span style={{fontFamily: 'Titles, sans-serif', letterSpacing: '0.5pt'}}>Buy</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                })}
              </center></div>
            <div className="prb justify-content-center">
              <div className="col-lg-12">
                <div className="p-sidebar-card p-sidebar-card-steps">
                  <div className="p-sidebar-card-body">
                    <div className="bs-stepper-content" id="WithdrawStep1" style={{}}>
                      <div className="text-center">
                        <h4 className="text-white" style={{fontSize: '2rem'}}>Experiences probability</h4>
                      </div>
                      <div className="row mt-4 d-flex justify-content-center">
                        <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                          <div className="p-select-card mb-4">
                            <div className="p-select-card-title"><b style={{fontFamily: 'Titles, sans-serif'}}>Level 1</b></div>
                            <div className="p-select-card-description">
                              <ul className="b">
                                <li><b>EXP:</b> 25 A 34 10%</li>
                                <li><b>EXP:</b> 35 A 45 15%</li>
                                <li><b>EXP:</b> 46 A 65 35%</li>
                                <li><b>EXP:</b> 66 A 85 40%</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                          <div className="p-select-card mb-4">
                            <div className="p-select-card-title"><b style={{fontFamily: 'Titles, sans-serif'}}>Level 2</b></div>
                            <div className="p-select-card-description">
                              <ul className="b">
                                <li><b>EXP:</b> 90 A 100 10%</li>
                                <li><b>EXP:</b> 101 A 119 15%</li>
                                <li><b>EXP:</b> 120 A 139 35%</li>
                                <li><b>EXP:</b> 140 A 150 40%</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                          <div className="p-select-card mb-4">
                            <div className="p-select-card-title"><b style={{fontFamily: 'Titles, sans-serif'}}>Level 3</b></div>
                            <div className="p-select-card-description">
                              <ul className="b">
                                <li><b>EXP:</b> 160 A 179 10%</li>
                                <li><b>EXP:</b> 180 A 199 15%</li>
                                <li><b>EXP:</b> 200 A 204 35%</li>
                                <li><b>EXP:</b> 205 A 220 40%</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                          <div className="p-select-card mb-4">
                            <div className="p-select-card-title"><b style={{fontFamily: 'Titles, sans-serif'}}>Level 4</b></div>
                            <div className="p-select-card-description">
                              <ul className="b">
                                <li><b>EXP:</b> 230 A 244 10%</li>
                                <li><b>EXP:</b> 245 A 259 15%</li>
                                <li><b>EXP:</b> 260 A 275 35%</li>
                                <li><b>EXP:</b> 276 A 290 40%</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                          <div className="p-select-card mb-4">
                            <div className="p-select-card-title"><b style={{fontFamily: 'Titles, sans-serif'}}>Level 5</b></div>
                            <div className="p-select-card-description">
                              <ul className="b">
                                <li><b>EXP:</b> 320 A 350 10%</li>
                                <li><b>EXP:</b> 351 A 360 15%</li>
                                <li><b>EXP:</b> 361 A 370 35%</li>
                                <li><b>EXP:</b> 371 A 400 40%</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
    </>
  );
}

export default HumanP;
