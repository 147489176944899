import * as React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHotkeys } from "react-hotkeys-hook";
import { useClickAway } from "react-use";
import {  XIcon } from "@heroicons/react/outline";
import { XCircleIcon } from "@heroicons/react/solid";
import ImgS3 from "../../components/ImgS3";

const ModalLose = ({ passenger, scan, open_, setScan, data }) => {
  const [open, setOpen] = React.useState(true);
  //const [scan, setScan] = React.useState(true);
  /* forms */
  /* forms-end */

  React.useEffect(() => {
    //setOpen(open_)
    console.log(open_);
    return () => {};
  }, [open_]);
  const ref = React.useRef(null);
  const boxRef = React.useRef(null);
  useHotkeys("cmd+k", () => setOpen(true));
  useHotkeys("ctrl+k", () => setOpen(true));
  useHotkeys("esc", () => setOpen(false));
  useClickAway(boxRef, () => setOpen(false));

  return (
    <>
      <Transition.Root as={React.Fragment} show={open}>
        <Dialog
          as="div"
          className="overflow-y-auto fixed inset-0 z-50"
          initialFocus={ref}
          onClose={setOpen}
          open={open}
          static
          style={{ zIndex: "99999999" }}
        >
          <div className="flex justify-center items-center px-4 pt-4 pb-20 min-h-screen text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#181c20] bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              aria-hidden="true"
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
            >
              &#8203;
            </span>
            <div className="bottom-40 inline-block align-bottom bg-purple-900 rounded-lg px-0 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6 text-cente">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="transition-all transform">
                  <div className="" ref={boxRef}>
                    <div className="hidden sm:block absolute top-0 right-0 pr-4">
                      <button
                        type="button"
                        className=" rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    <div className="hidden sm:block absolute top-0 right-0 pr-4">
                      <button
                        type="button"
                        className=" rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div class="swal2-html-container" id="swal2-html-container">
                      <div class="img_succes_xp mb-3">
                        {" "}
                        <ImgS3 src="/lose.svg" alt="" />
                      </div>
                      <div class="">
                        <div class="Lose bg-red-200 rounded-full">
                          {" "}
                          <span class="lose_sp">
                            <XCircleIcon
                              className="h-5 w-5 text-red-700"
                              aria-hidden="true"
                            />
                            <p>
                              <b>Lose scanning!</b>
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default ModalLose;
