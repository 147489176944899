import React, { useState, useEffect, useRef, useContext } from "react";
import { level } from "../../context/data";
import ReactPaginate from "react-paginate";
import { ethers, utils } from "ethers";
import MarketplaceContext from "../../context/MarketplaceContextU";
import clsx from "clsx";
import ImgS3 from "../../components/ImgS3";


const RocketsMarket = [
  {
    id: 1,
    name: "ROCKET 1",
    seat: 2,
    href: "#",
    box: 3500,
    imageSrc: "/images/marketplace/rocket/level1.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    class: "Basic",
    post: 0,
    remainingFee: 0
  },
  {
    id: 2,
    name: "ROCKET 2",
    seat: 4,
    href: "#",
    box: 3500,
    imageSrc: "images/marketplace/rocket/level2.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    class: "Economic",
    post: 0,
  },
  {
    id: 3,
    name: "ROCKET 3",
    seat: 6,
    href: "#",
    box: 3000,
    imageSrc: "/images/marketplace/rocket/level3.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    class: "Executive",
    post: 0,
  },
  {
    id: 4,
    name: "ROCKET 4",
    seat: 8,
    href: "#",
    box: 2600,
    imageSrc: "/images/marketplace/rocket/level4.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    class: "Boss",
    post: 0,
  },
  {
    id: 5,
    name: "ROCKET 5",
    seat: 8,
    href: "#",
    box: 1800,
    imageSrc: "/images/marketplace/rocket/level5.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    class: "Premium",
    post: 0,
  },
];

export default function RocketW({ object }) {
  const [searchClass, setSearchClass] = useState("");
  const [searchPrices, setSearchPrices] = useState("");
  const [searchTime, setSearchTime] = useState("");
  const [page, setPage] = useState(0);
  const [employeesPerPage, setemployeesPerPage] = useState(33);
  const [numberOfEmployeesVistited, setnumberOfEmployeesVistited] = useState(
    page * employeesPerPage
  );
  const [loading, setLoading] = useState(true);
  const [rockets, setRockets] = useState([]);

  useEffect(() => {
    if (object.length == 0) {
      return;
    }
    let obj = object.map((value) => {
      const data_ = RocketsMarket[value.level - 1];
      {
        // id: 1,
        // name: "ASTRONAUT 1",
        // href: "#",
        // box: 3500,
        // imageSrc: "/images/marketplace/human/level1.png",
        // imageAlt: "Front of men's Basic Tee in black.",
        // price: 16,
        // exp: 20,
        // post: 200,
        // level: "Admin",
      }
      return Object.assign(
        {
          name: data_.name,
          imageSrc: data_.imageSrc,
          imageAlt: data_.imageAlt,
          level: data_.level,
        },
        value
      );
    });

    setRockets(obj);
    return () => {};
  }, [object]);

  useEffect(() => {
    const time = setTimeout(() => {
      setLoading(false);
    }, 10000);

    return () => {
      clearTimeout(time);
    };
  }, []);

  function refreshPage() {
    window.location.reload();
  }

  const totalPages = Math.ceil(rockets.length / employeesPerPage);
  const changePage = ({ selected }) => {
    setPage(selected);
  };

  useEffect(() => {
    setnumberOfEmployeesVistited(page * employeesPerPage);
  }, [page]);
  const classCheck = [
    {
      class: "Basic",
      id: 1,
    },
    {
      class: "Economic",
      id: 2,
    },
    {
      class: "Executive",
      id: 3,
    },
    {
      class: "Boss",
      id: 4,
    },
    {
      class: "Premium",
      id: 5,
    },
  ];

  return (
    <div className="relative ">
      <main className="mx-auto mx-8 " style={{ maxWidth: "110rem" }}>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5 col-span-full">
          {/* Payment details */}
          <div className="space-y-6 sm:px-6 lg:px-0 col-span-full">
            <section>
              {loading && rockets.length == 0 ? (
                <div class="flex justify-center items-center mt-20">
                  <div class="animate-spin rounded-full h-36 w-36 border-t-2 border-b-2 border-white"></div>
                </div>
              ) : null}
              {!loading && rockets.length == 0 ? (
                <>
                  <div class="flex justify-center items-center mt-20">
                    <div>
                      <h1>DATA NO FOUND</h1>
                    </div>
                  </div>

                  <div class="flex justify-center items-center mt-20">
                    <button
                      onClick={refreshPage}
                      type="button"
                      className=" px-2.5 py-1.5 border border-transparent text-xl font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Reload
                    </button>
                  </div>
                </>
              ) : null}
              <div className="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-3 mx-auto max-w-4xl">
                {rockets.length > 0
                  ? rockets
                      .slice(
                        numberOfEmployeesVistited,
                        numberOfEmployeesVistited + employeesPerPage
                      )
                      .filter((employeesPerPage) => {
                        if (searchClass === "") {
                          return employeesPerPage;
                        } else if (
                          employeesPerPage.class
                            .toLowerCase()
                            .includes(searchClass.toLowerCase())
                        ) {
                          return employeesPerPage;
                        }
                      })
                      .sort((a, b) => {
                        if (searchTime === "oldest") {
                          return b.post - a.post;
                        }
                        return a, b;
                      })
                      .sort((a, b) => {
                        if (searchPrices === "higher") {
                          return b.price - a.price;
                        }
                        return a, b;
                      })
                      .map((rocket, id) => (
                        <RocketItem rocket={rocket} key={id} />
                      ))
                  : null}
              </div>
              <div className="mt-6">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={totalPages}
                  onPageChange={changePage}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"navigationActive"}
                />
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
}

function RocketItem({ rocket }) {
  const {
    isApproveNft,
    isApprove,
    buy,
    setgameupdate,
    gameApproveHandle,
    ApproveHandleNft,
    cancelSellToken,
    askList,
  } = useContext(MarketplaceContext);

  return (
    <>
        <div estin_ text-vd7 className="relative__hb_hu flex justify-center">
    <div
      className={clsx("card crd ", {
        "admin ": rocket.level === "1",
        academic: rocket.level === "2",
        engineer: rocket.level === "3",
        "boss ": rocket.level === "4",
        robot: rocket.level === "5",
      })}
      style={{ height: "441px" }}
    >
      <div className="nft-title">
        <h3> {rocket.name}</h3>
      </div>
      <div estin_ className="card-fee-data">
        <div estin_>
        
          {/*
          <p estin_ className="fdes text-center">
            <span estin_ className="is-text-regular is-color-lightGrey">
              Usage:
            </span>{" "}
            <span estin_ className="is-text-regular ranft">
              {usage} days
            </span>
          </p>
          
          */}
          
        </div>
      </div>
      <div className="card-body">
        <div className="aspect-h-3 aspect-w-3 rounded-lg ">
          <ImgS3
            src={rocket.imageSrc}
            alt={rocket.imageAlt}
            loading="lazy"
            className="crd-mainimg"
          />
        </div>
        <div className="crd-tags_nft clearfix ml-0">
          <span className="crd-tag_nft">Class: {rocket.class}</span>
          <span className="crd-tag_nft" style={{ marginLeft: "8px" }}>
            EXP: {rocket.exp}
          </span>
        </div>
      </div>
      <div className="data_hw">
        <div className="flex-ston ds_1">
          <div className="flex-crit">
            <div className="mt-2">
              <div>{utils.formatEther(rocket.price)} SSAP</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-2">
      {isApprove && isApproveNft ? (
          <div className="flex">

            <button
                 onClick={() => cancelSellToken(rocket.tokenId)}
                class="actb approve w-20"
                style={{ width: "179px" }}
              >
                <span
                  style={{
                    fontFamily: "Titles, sans-serif",
                    letterSpacing: "0.5pt",
                  }}
                >
                   CANCEL SALE
                </span>
              </button>
          </div>
        ) : (
          <div className="flex">
            {!isApprove && (
             <button
             onClick={() => gameApproveHandle()}
             class="actb approve w-20"
             style={{ width: "179px" }}
           >
             <i class="fas fa-scroll"></i>
             <span
               style={{
                 fontFamily: "Titles, sans-serif",
                 letterSpacing: "0.5pt",
               }}
             >
               Approve Token
             </span>
           </button>
            )}

            {!isApproveNft && (
            <button
            onClick={() => ApproveHandleNft()}
            class="actb approve"
            style={{ width: "171px" }}
          >
            <i class="fas fa-scroll"></i>
            <span
              style={{
                fontFamily: "Titles, sans-serif",
                letterSpacing: "0.5pt",
              }}
            >
              Approve NFT
            </span>
          </button>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
   
    </>
  );
}
