import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import { constants, BigNumber, ethers } from "ethers";
import UseCheckBook from "../../hooks/UseCheckBook";
import Web3Context from "../../context/Web3Context";
import InventoryContext from "../../context/Inventory";
import { UseGame, UseHuman } from "../../hooks/useContracts";
import ModalSell from "./ModalSell";
import ModalGift from "./ModalGift";
import { motion } from "framer-motion";
import ButtonPopoverHuman from "./ButtonPopoverHuman";
import { useToasts } from "react-toast-notifications";
import { utils } from "ethers";
import ModalFeesAll from "./ModalFeesAll";
import { NotificationMessage } from "../../components/NotificationMessage/NotificationMessage";
import { Circle } from "../../components/Circle";
import ReactPaginate from "react-paginate";
import LevelUp from "./LevelUp/LevelUp";
import { useSpinDelay } from "spin-delay";
import { DateTime } from "luxon";
import ModalInfoDead from "./ModalInfoDead";
import ImgS3Ximbia from "../../components/ImgS3Ximbia";

const experience = [
  { id: 1, name: "High" },
  { id: 2, name: "Low" },
];

const LOADER_WORDS = [
  "Loading...",
  "Checking cdn",
  "Checking cache",
  "Fetching from BSC Network",
  "Updating cache",
  "Transfer",
];

function HumanCard({ employees, checkedBoxHandle, person }) {
  const { addToast } = useToasts();
  const Human = UseHuman();
  const Game = UseGame();
  const { accounts, isLoaded, setupdate, update } = useContext(Web3Context);
  const { people, generalData, updateHandle } = useContext(InventoryContext);
  //const [state, setState] = useState(() => new Set());
  const [humanPayFeesArrayUSD, sethumanPayFeesArrayUSDl] = useState([
    6, 14, 18, 22, 28,
  ]);
  const feeDays = [30, 30, 30, 15, 15]; 
  const [searchLevel, setSearchLevel] = useState("");
  const [searchExp, setSearchExp] = useState("");
  const [exhausted, setExhausted] = useState(true);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [timeDead, settimeDead] = useState(0);
  const [usage, setusage] = useState(0);
  const [time, settime] = useState(0);

  useEffect(() => {
    if (person.lastDate == undefined || person.lastDate == 0) return;
    if(person.id == 0 || person.id == undefined || person.id > 5) return;
    const newTime = DateTime.fromSeconds(Number(person.lastDate)).plus({
      day: feeDays[person.id - 1],
    });
    const time_ = DateTime.now();
    //  console.log(time_.toFormat('yy LL dd'),newTime.toFormat('yy LL dd')  );
    const dif = newTime - time_;
    if (dif > 0) {
      const value_ = newTime.diff(time_, ["days"]).toObject().days.toFixed(0);

      settime(value_);
    }
    return () => {};
  }, [person]);

  const dead = async (tokenId) => {
    try {
      const [load, contract] = await Human;
      const res = await contract["safeTransferFrom(address,address,uint256)"](
        accounts,
        "0x000000000000000000000000000000000000dEaD",
        tokenId
      );
      addToast("Dead success", { appearance: "success" });
      setStatus("successfully");
      res.wait().then(() => {
        setStatus("Complete");
        updateHandle();
      });
    } catch (error) {
      if (error.data.message !== undefined) {
        addToast(error.data.message, { appearance: "error" });
      } else {
        addToast(error.message, { appearance: "error" });
      }
    }
  };
  const transfer_ = async (tokenId, to) => {
    console.log(tokenId, to);
    setStatus("successfully");
    try {
      const [load, contract] = await Human;
      await contract["safeTransferFrom(address,address,uint256)"](
        accounts,
        to,
        tokenId
      );
      addToast("tranfer success", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
      setStatus("Complete");
    } catch (error) {
      if (error.data.message !== undefined) {
        addToast(error.data.message, { appearance: "error" });
      } else {
        addToast(error.message, {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      }
    }
  };

  //mintDate:value.mintDate,
  //maxAge:value.maxAge,us

  useEffect(() => {
    try {
      const nowDate = DateTime.now();
      const handle = DateTime.fromSeconds(
        Number(person.maxAge.add(person.mintDate))
      );
      //console.log(handle.diff(nowDate).as("days"));
      setusage(handle.diff(nowDate).as("days").toFixed(0));
    } catch (e) {
      console.log(e, "Error");
    }
    return () => {};
  }, [person]);

  return (
    <>
      <div estin_ text-vd7  className={clsx("relative__hb_hu ")} style={{display: 'block'}}>
        <div className={clsx("card crd ",
        {
          "admin ": person.id === 1,
          "academic": person.id === 2,
          "engineer": person.id === 3,
          "boss ": person.id === 4,
          "robot": person.id === 5,
        }
        )}>
          <div className="card-body">
           
            
            <div className="group peer relative block w-full focus:outline-none">
            <div className="aspect-h-3 aspect-w-3 rounded-lg bg-gray-500 cursor-pointer video hover:ring-2 hover:ring-offset-2 hover:ring-primary-500">
              <ImgS3Ximbia
              alt={`human-${person.id}`}
                src={`${person.previewUrl}`}
                className="video hover:bg-blend-darken shadow-black focus-ring w-full rounded-lg object-cover object-center transition transition-opacity"
              />
            </div>
          </div>
          <div estin_ className="card-bottom-data " style={{paddingLeft: '8px', paddingRight: '8px'}}>
              <div estin_>
                <p estin_ className="level-txth mt-2"><span estin_ className="is-text-regular is-color-lightGrey"></span>#{person.tokenID}<span estin_ className="is-text-regular ranft"></span></p>
              </div>
            </div>
            <div className="crd-taginf clearfix"><span className="crd-tag">Level: {person.level}
              </span><span className="crd-tag" style={{marginLeft: '8px'}}>EXP: {person.exp}</span>
            </div>
            <div className="crd-title">
              <h3>Usage: {usage} Days</h3>
            </div>
            <div estin_ className="card-bottom-data " style={{paddingLeft: '8px', paddingRight: '8px'}}>
              <div estin_>
                <p estin_ className="level-txth"><span estin_ className="is-text-regular is-color-lightGrey">Remaining fee:</span> <span estin_ className="is-text-regular ranft">{person.remainingFee} days</span></p>
              </div>
            </div>            
            <div className="absolute z-10 left-2 top-12 p-1 w-4 h-4   focus:outline-none  group-hover:opacity-100 peer-hover:opacity-100 hover:opacity-100 peer-focus:opacity-100 focus:opacity-100 transition   lg:opacity-0  absolute z-10 ">
            <span className="relative z-0 shadow-sm rounded-md w-40  ">
              <div className="multi-button">
                <button
                  type="button"
                  className={
                    person.dead || !person.exhausted
                      ? "hidden"
                      : " border border-gray-300 bg-indigo-600 hover:bg-white text-sm font-medium text-white hover:text-indigo-800 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  }
                >
                  <input
                    id="feeds"
                    aria-describedby="feeds"
                    name="feeds"
                    type="checkbox"
                    className=""
                    onChange={(e) => {
                      console.log(e.target.checked);
                      checkedBoxHandle(e.target.checked, person.tokenID, 0);
                    }}
                  />
                </button>
                {person.dead ? (
                  <ModalInfoDead
                    onClick={() => {
                      dead(person.tokenID);
                    }}
                  />
                ) : null}
                <ModalSell
                  transfer={transfer_}
                  id={person.tokenID}
                  isDead={person.dead}
                />
                <ModalGift
                  transfer={transfer_}
                  tokenID={person.tokenID}
                  isDead={person.dead}
                  id={person.tokenID}
                />
              </div>
            </span>
          </div> 

          {person.exhausted && (
            <div className="absolute z-10 left-32 top-2 text-xl p-1 w-20 h-4 font-bold  focus:outline-none   absolute z-10 ">
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                Exhausted
              </span>
            </div>
          )}
          {person.dead && (
            <div className="absolute z-10 left-8 top-2 text-xl p-1 w-20 h-4 font-bold  focus:outline-none   absolute z-10 ">
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                Dead
              </span>
            </div>
          )}



          </div>
          <div className="mt-9 mb-1 ml-2">
            <div>
              <div className="flex -mt-1 ml-2">
                <LevelUp employees={employees} person={person} />
              </div>
            </div>
          </div>
        
        </div>
      </div>


   
    </>
  );
}

export default HumanCard;
