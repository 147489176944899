/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from "react";
import PROVIDER from "../hooks/provider";
import { useToasts } from "react-toast-notifications";
import Web3Context from "./Web3Context";
import {
  UseHuman,
  UseGame,
  useToken,
  address,
  useMarketplaceU,
} from "../hooks/useContracts.js";
import { BigNumber, constants, ethers,utils } from "ethers";
import { DateTime } from "luxon";

const MarketplaceContextU = createContext();

const MarketplaceProviderU = ({ children }) => {
  const { addToast } = useToasts();
  const { accounts, isLoaded, setupdate, update, etherJSProvider } =
    useContext(Web3Context);
  const [update_, setupdate_] = useState(0);
  const [balanceOf, setbalanceOf_] = useState(BigNumber.from("0"));
  const [gameupdate, setgameupdate] = useState(0);
  const [isApprove, setisApprove] = useState(true);
  const [isApproveNft, setisApproveNft] = useState(false);
  const [askList, setaskList] = useState([]);
  const [Listing, seatListing] = useState([]);

  const Marketplace = useMarketplaceU();
  const Human = UseHuman();
  const Token = useToken();

  useEffect(() => {
    if (!isLoaded) return;
    // AllowanceHandle();
    // gameAllowanceHandle();
    // getAsk();
    // getListing();

    return () => {};
  }, [accounts, isLoaded, gameupdate]);

  // [0].tokenId
  // [0].price
  const getAsk = async () => {
    const [load, contract] = await Marketplace;
    const [, contract_] = await Human;
    const ask = await contract.getAsks();
    const tokenIDs = ask.map((value) => value.tokenId.toString());
    let askBlock = await contract_.getNftBatch(tokenIDs);
    // console.log(
    //   askBlock,
    //   "useMarketplaceUuseMarketplaceUuseMarketplaceUuseMarketplaceUuseMarketplaceU"
    // );
    askBlock = askBlock.map((value, i) => {
      const _level = value.level;
      const feeDays = [30, 30, 30, 15, 15];
      let newTime = 0;
      let _newTime;
      if(_level > 0 && _level != undefined && _level <= 5) {
        _newTime = DateTime.fromSeconds(Number(value.lastDate)).plus({
        day: feeDays[_level - 1],
      });
      const time_ = DateTime.now()
      const dif =  _newTime - time_
      if (dif > 0) {
        newTime = _newTime.diff(time_, ["days"]).toObject().days.toFixed(0);
      }
      }
      return {
        tokenId: ask[i].tokenId.toString(),
        price: ask[i].price,
        level: value.level.toString(),
        maxAge: value.maxAge.toString(),
        seat: value.seat.toString(),
        exp: value.exp.toString(),
        lastDate:value.lastDate.toString(),
        remainingFee: newTime,
      };
    });
    setaskList(askBlock);
  };

  const getListing = async () => {
    const [load, contract] = await Marketplace;
    const [, contract_] = await Human;
    const ask = await contract.getAsksByUser(accounts);
    const tokenIDs = ask.map((value) => value.tokenId.toString());
    let askBlock = await contract_.getNftBatch(tokenIDs);
    // console.log(
    //   askBlock,
    //   "Mi listingggg msadasdada da da dasadasdas d"
    // );
    askBlock = askBlock.map((value, i) => {
      return {
        tokenId: ask[i].tokenId.toString(),
        price: ask[i].price,
        level: value.level.toString(),
        //class: value.class.toString(),
        maxAge: value.maxAge.toString(),
        seat: value.seat.toString(),
        exp: value.exp.toString(),
      };
    });
    seatListing(askBlock);
  };

  const buy = async (id) => {
    
    try {
      const [load, contract] = await Marketplace;

      const res = await contract.buyToken(id,{value:utils.parseEther("0.0011")});
      addToast("success", { appearance: "success" });
      res.wait().then(() => {
        addToast("readyToSellToken success", { appearance: "success" });
        // setStatus("Complete");
        // resetCheckedBoxHandle();
      });
    } catch (error) {
      console.log(error);
      if (error.data !== undefined) {
        addToast(error.data.message, { appearance: "error" });
      } else {
        addToast(error.message, { appearance: "error" });
      }
    }
  };

  const cancelSellToken = async (id) => {
    try {
      const [load, contract] = await Marketplace;

      const res = await contract.cancelSellToken(id);
      addToast("success", { appearance: "success" });
      res.wait().then(() => {
        addToast("readyToSellToken success", { appearance: "success" });
        // setStatus("Complete");
        // resetCheckedBoxHandle();
      });
    } catch (error) {
      console.log(error);
      if (error.data !== undefined) {
        addToast(error.data.message, { appearance: "error" });
      } else {
        addToast(error.message, { appearance: "error" });
      }
    }
  };

  const gameApproveHandle = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    const [load, contract] = await Token;
    const addr = address.MarketplaceU;
    const res = await contract.approve(addr, constants.MaxUint256);    
    res.wait().then(() => setgameupdate(gameupdate + 1));
  };

  const ApproveHandleNft = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    // console.log("paso");
    const [load, contract] = await Human;
    const addr = address.MarketplaceU;
    const res = await contract.setApprovalForAll(addr, true);
    res.wait().then(() => setgameupdate(gameupdate + 1));
  };

  const AllowanceHandle = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    const [load, contract] = await Human;
    const addr = address.MarketplaceU;
    const res = await contract.isApprovedForAll(accounts, addr);
    setisApproveNft(res);
  };

  const gameAllowanceHandle = async () => {
    if (
      !isLoaded &&
      accounts != "000000000000000000000000000000000000000000000"
    )
      return;
    const [load, contract] = await Token;
    const addr = address.MarketplaceU;
    const allowance_ = await contract.allowance(accounts, addr);
    setisApprove(allowance_.gt(constants.MaxUint256.div(5)));
  };

  const datas = {
    gameApproveHandle,
    askList,
    ApproveHandleNft,
    isApproveNft,
    isApprove,
    setgameupdate,
    cancelSellToken,
    buy,
    Listing,
  };

  return (
    <MarketplaceContextU.Provider value={datas}>
      {children}
    </MarketplaceContextU.Provider>
  );
};

export { MarketplaceProviderU };
export default MarketplaceContextU;
