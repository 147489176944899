import { Fragment, useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import Web3Context from "../../context/Web3Context";
import TokenContext from "../../context/TokenHandle";
import InventoryContext from "../../context/Inventory";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import { DuplicateIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { Dialog, Menu, Transition } from "@headlessui/react";
import Dropdown from "./Dropdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { NotificationMessage } from "../NotificationMessage/NotificationMessage";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Circle } from "../Circle";
import ImgS3 from "../ImgS3";
import Player from "./Player";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Navbar() {
  const { accounts, isLoaded, balanceOf_ } = useContext(Web3Context);
  const {} = useContext(InventoryContext);

  const { gameApproveHandle, balanceOf, isApprove } = useContext(TokenContext);

  const [acount_, setacount_] = useState("0x");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };



  useEffect(() => {
    const start = "0X...";
    const end = accounts.slice(38);
    setacount_(`${start}...${end}`);
    return () => {};
  }, [accounts]);

  const NAVBAR_DATA = [
    {
      title: "Play",
      to: "/home",
    },
    {
      title: "Inventory",
      to: "/inventory",
    },
    /*
    
       {
      title: "Marketplace",
      to: "/marketplaceUpdate",
    },

    {
      title: "My Listings",
      to: "/walletU",
    }, 
    
    */

  ];

  let audio = new Audio("/audio.mp3");

  const start = () => {
    audio.play();
    audio.volume = 0.2;
    audio.loop = true;
  };

  const stop = () => {
    audio.pause();
    //button will update its text to play
    audio.innerHTML = "Play";
    audio.currentTime = 0;
  };

  return (
    <header className='border-b-2 border-purple-400'>
      {isCopied && (
        <NotificationMessage position='top-center'>
          <div className={clsx("flex items-center w-68")}>
            <motion.div
              className='absolute'
              transition={{ repeat: Infinity, duration: 2, ease: "linear" }}
              animate={{ rotate: 360 }}
            >
              <Circle size={56} color='UNKNOWN' />
            </motion.div>
            <svg
              stroke='currentColor'
              fill='currentColor'
              stroke-width='0'
              viewBox='0 0 1024 1024'
              className={clsx("inline w-6 ml-4")}
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M864 736c0-111.6-65.4-208-160-252.9V317.3c0-15.1-5.3-29.7-15.1-41.2L536.5 95.4C530.1 87.8 521 84 512 84s-18.1 3.8-24.5 11.4L335.1 276.1a63.97 63.97 0 0 0-15.1 41.2v165.8C225.4 528 160 624.4 160 736h156.5c-2.3 7.2-3.5 15-3.5 23.8 0 22.1 7.6 43.7 21.4 60.8a97.2 97.2 0 0 0 43.1 30.6c23.1 54 75.6 88.8 134.5 88.8 29.1 0 57.3-8.6 81.4-24.8 23.6-15.8 41.9-37.9 53-64a97 97 0 0 0 43.1-30.5 97.52 97.52 0 0 0 21.4-60.8c0-8.4-1.1-16.4-3.1-23.8L864 736zM512 352a48.01 48.01 0 0 1 0 96 48.01 48.01 0 0 1 0-96zm116.1 432.2c-5.2 3-11.2 4.2-17.1 3.4l-19.5-2.4-2.8 19.4c-5.4 37.9-38.4 66.5-76.7 66.5s-71.3-28.6-76.7-66.5l-2.8-19.5-19.5 2.5a27.7 27.7 0 0 1-17.1-3.5c-8.7-5-14.1-14.3-14.1-24.4 0-10.6 5.9-19.4 14.6-23.8h231.3c8.8 4.5 14.6 13.3 14.6 23.8-.1 10.2-5.5 19.6-14.2 24.5z'></path>
            </svg>

            <div className='inline-grid ml-10'>
              <div className='flex col-start-1 row-start-1 overflow-hidden'>
                Has been copied successfully
              </div>
            </div>
          </div>
        </NotificationMessage>
      )}

      <nav className='navbar estin h-20'>
        <div className='logo'>
       <img src="https://ximbia-frontend.vercel.app/favicon.png" className="w-12"/>
          <a href='#'>Ximbia Game</a>
        </div>
        {/*Sidebar Mobile */}
        <button
          type='button'
          className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
          onClick={() => setSidebarOpen(true)}
        >
          <span className='sr-only'>Open sidebar</span>
          <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
        </button>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='fixed inset-0 flex z-40 md:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-purple-grandient '>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute top-16 right-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className='flex-shrink-0 flex items-center px-4'></div>
                <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                  <nav className='px-12 space-y-1 mt-16'>
                    <ul className='nav_menu_list'>
                      {NAVBAR_DATA.map((data, index) => {
                        return (
                          <li
                            key={index}
                            className='nav_menu_item nav-text'
                            index={index}
                          >
                            <NavLink
                              to={data.to}
                              onClick={() => setSidebarOpen(false)}
                              className='nav_menu_item_link text-gray-700'
                              activeClassName='nav_menu_item_link active'
                            >
                              {data.title}
                            </NavLink>
                          </li>
                        );
                      })}
                      <li className='nav_menu_item'>
                        <button class='register_btn btn3 px-4'>{acount_} </button>
                      </li>
                      <li className='nav_menu_item'>
                        <button class='register_btn btn3 px-2'>0 XMB</button>
                      </li>
            
                      {!isApprove ? (
                        <li className='nav_menu_item'>
                          <button
                            class='register_btn btn3 px-2'
                            onClick={async () => {
                              gameApproveHandle();
                            }}
                          >
                            approve
                          </button>
                        </li>
                      ) : null}
                    </ul>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className='flex-shrink-0 w-14' aria-hidden='true'>
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>
        {/*Sidebar Mobile End*/}
        <div className='nav_menu ' id='nav_menu'>
          {" "}
          <ul className='nav_menu_list'>
            {NAVBAR_DATA.map((data, index) => {
              return (
                <li className='nav_menu_item' index={index}>
                  <NavLink
                    to={data.to}
                    className='nav_menu_item_link'
                    activeClassName='nav_menu_item_link active'
                  >
                    {data.title}
                  </NavLink>
                </li>
              );
            })}
            <div className='nav_menu_item'>
              {/* Profile dropdown */}
              <Menu as='div' className='ml-3 relative'>
                <div>
                  <Menu.Button className='register_btn btn3 -mt-2 px-4'>
                    <span className='sr-only'>Open user menu</span>
                    {acount_}
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-200'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'
                >
                  <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none z-10'>
                    <Menu.Item>
                      {() => (
                        <div className='flex-none'>
                          <div
                            className={classNames(
                              "block px-4 py-2 text-xs text-gray-700"
                            )}
                          >
                            Wallet address:
                          </div>
                          <div
                            className={classNames(
                              "block px-4 py-2 text-xs text-gray-700 flex"
                            )}
                          >
                            {acount_}
                            <CopyToClipboard
                              text={accounts}
                              onCopy={onCopyText}
                            >
                              <DuplicateIcon
                                className={classNames(
                                  "-mt-1 ml-3 text-xs text-gray-700 cursor-pointer w-6"
                                )}
                              />
                            </CopyToClipboard>
                            <a
                              href={`https://bscscan.com/address/${accounts}`}
                              target='_blank'
                            >
                              <ExternalLinkIcon
                                className={classNames(
                                  "-mt-1 text-xs ml-2 text-gray-700 cursor-pointer w-6"
                                )}
                              />
                            </a>
                          </div>
                        </div>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <li className='nav_menu_item'>
              <button class='register_btn btn3 -mt-2 px-2'>
                {balanceOf.toString()} XMB{" "}
              </button>
            </li>
            {!isApprove ? (
              <li className='nav_menu_item'>
                <button
                  class='register_btn btn3 px-2'
                  onClick={async () => {
                    gameApproveHandle();
                  }}
                >
                  approve
                </button>
              </li>
            ) : null}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
