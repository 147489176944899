import React, { useContext, useEffect, useState } from "react";
import "../../css/game/wactus.css";
import "../../css/game/stplay.css";
import { PlanetData } from "../../data/PlanetData";
import InventoryContext from "../../context/Inventory";
import SlidePlay from "./SlidePlay";
import ImgS3 from "../../components/ImgS3";
import { UseGame } from "../../hooks/useContracts";
import { useToasts } from "react-toast-notifications";
//import { useCountdown } from '../../hooks/useCountdown';
import StabHandle, {
  Win,
  Loser,
  Withdraw,
  Timer,
} from "./PlayHandle/Stabhandle";
import PlanetCard from "./Planet";

function PlayScreen({
  status,
  setStatus,
  notification,
  setNotification,
  isStatus,
  setIsStatus,
}) {
  const Game = UseGame();
  const { addToast } = useToasts();
  const { people, expSelect, generalData } = useContext(InventoryContext);
  const height = "1367px";

  return (
    <div
      id="hakkv "

      className="absolute top-0 w-full bg-no-repeat bg-cover lg:bg-fixed bg-scroll z-0 bg-background"
    >
           <div className="flex items-end justify-center w-full h-full absolute -z-10">
     
        </div>
      <div className=" mx-auto max-w-screen-2xl">
        <div className="hakkv_sx ">
          <h1 className="mb-4 text-white">
       
            Game Station
          </h1>
          <StabHandle generalData={generalData} />

          <div className="row ">
            <div className="col-12 col-lg-6 ">
              <div className="spx_data bg-primary border border-purple-700">
                <div className="spx-icon ">
                  <ImgS3
                    src={`/images/game/assets/power.png`}
                    className="w-12 hidden lg:block"
                    alt=""
                  />
                </div>
                <div className="spx-content break-words">
                  <p className="spx-title">Current space expedition power</p>
                  <p className="spx-value py-4 lg:py-1">
                    {generalData.seatAvailable} Seat
                  </p>
                  <p className="spx-description">
                    Based on the current EXP of your space expedition.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="spx_data bg-primary border border-purple-700">
                <div className="spx-icon">
                  <ImgS3
                    src={`/images/game/assets/planets.png`}
                    className="w-12 hidden lg:block"
                    alt=""
                  />
                </div>
                <div className="spx-content">
                  <p className="spx-title">Maximum level of planets</p>
                  <p className="spx-value py-4 lg:py-1">{expSelect} EXP</p>
                  <p className="spx-description">
                    Based on the Required EXP of the planet.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <ul className="stinplat">
                {PlanetData.map((planet, index) => (
                  <PlanetCard
                    key={index}
                    status={status}
                    setStatus={setStatus}
                    notification={notification}
                    setNotification={setNotification}
                    isStatus={isStatus}
                    setIsStatus={setIsStatus}
                    planet={planet}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayScreen;
