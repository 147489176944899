import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import { constants, BigNumber, ethers } from "ethers";
import UseCheckBook from "../../hooks/UseCheckBook";
import Web3Context from "../../context/Web3Context";
import InventoryContext from "../../context/Inventory";
import TokenHandle from "../../context/TokenHandle";
import { UseGame, UseHuman, UseOracle,useBuyHumanFactory } from "../../hooks/useContracts";
import ModalSell from "./ModalSell";
import ModalGift from "./ModalGift";
import { motion } from "framer-motion";
import ButtonPopoverHuman from "./ButtonPopoverHuman";
import { useToasts } from "react-toast-notifications";
import { utils } from "ethers";
import ModalFeesAll from "./ModalFeesAll";
import { NotificationMessage } from "../../components/NotificationMessage/NotificationMessage";
import { Circle } from "../../components/Circle";
import ReactPaginate from "react-paginate";
import LevelUp from "./LevelUp/LevelUp";
import { useSpinDelay } from "spin-delay";
import HumanCard from "./HumanCard";
import ModalFeesAllHuman from "./ModallFeesAllHuman";
import MisteryCard from "./MisteryCard";
// import { Game } from "../../hooks/abiHelpers";

const experience = [
  { id: 1, name: "High" },
  { id: 2, name: "Low" },
];

const LOADER_WORDS = [
  "Loading...",
  "Checking cdn",
  "Checking cache",
  "Fetching from BSC Network",
  "Updating cache",
  "Transfer",
];

function Human() {
  const [state, checkedBoxHandle, resetCheckedBoxHandle] = UseCheckBook();
  const { addToast } = useToasts();
  const Human = UseHuman();
  const BuyHumanFactory = useBuyHumanFactory();
const Game =  UseGame();
  const Oracle = UseOracle();
  const { accounts, isLoaded, setupdate, update } = useContext(Web3Context);
  const { people, generalData, updateHandle } = useContext(InventoryContext);
  const { buyApproveHandle,
    isApproveBuy,
    gameupdateHandle
  ,buylenght
  } = useContext(TokenHandle);



  
  //const [state, setState] = useState(() => new Set());
  const [humanPayFeesArrayUSD, sethumanPayFeesArrayUSDl] = useState([
    6, 14, 18, 22, 28,
  ]);
  const [searchLevel, setSearchLevel] = useState("");
  const [searchExp, setSearchExp] = useState("");
  const [exhausted, setExhausted] = useState(true);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [price, setPrice] = useState(
    BigNumber.from(ethers.utils.parseEther("0"))
  );
  const [page, setPage] = useState(0);
  const [employees, setEmployees] = useState(people);
  const [employeesPerPage, setemployeesPerPage] = useState(21);
  const [numberOfEmployeesVistited, setnumberOfEmployeesVistited] = useState(
    page * employeesPerPage
  );
 
  const [mistery, setmistery] = useState(null);



  useEffect(() => {
    console.log(buylenght,"buylenghtbuylenghtbuylenghtbuylenght");
    const index_=0
    console.log();
    if(Number(buylenght[index_])==0)
    return setmistery(null)

    let card_=[]
    for (let index = 0; index < buylenght[index_]; index++) {
       card_[index] = <MisteryCard text={`Human`} key={index} index={index} _type={index_}/>;      
    }
    setmistery(card_)
    
    return () => {
      
    };
  }, [buylenght,accounts,isLoaded]);
  
  useEffect(() => {
    const time = setTimeout(() => {
      setLoading(false);
    }, 10000);

    return () => {
      clearTimeout(time);
    };
  }, []);

  useEffect(() => {
    let people_ = people;
    // people_.sort((a, b) => {
    //   if (!a.dead && !a.exhausted) return -1;
    //   if (a.dead) return 1;
    //   return 0;
    // });

    setData(people_);
    setEmployees(people_);
    // priceO();
  }, [people]);

  const priceO = async () => {
    if (!isLoaded) return;
    const [load, contract] = await Oracle;
    const res = await contract.getBUSDToToken(
      process.env.REACT_APP_TOKEN,
      ethers.utils.parseEther("20")
    );
    setPrice(BigNumber.from(res));
    console.log(price.toString(), "Price");
  };

  const buy = async () => {
    try {
      const [load, contract] = await Game;
      console.log("Buy");
      // let estimateGas = await contract.estimateGas.buyNFT(0);
      // estimateGas = estimateGas.add(
      //   estimateGas.mul(BigNumber.from("10")).div("100")
      // );
      const res = await contract.buyNFT(0, { 
        gasPrice: ethers.utils.parseUnits("6", "gwei"),
        gasLimit: 1e6,      
        // value:utils.parseEther("0.0014")10
      });
      addToast("Buy success", { appearance: "success" });
      setStatus("successfully");
      res.wait().then(() => {
        addToast("complete success", { appearance: "success" });
        setStatus("Complete");
        updateHandle();
        gameupdateHandle();
      });
    } catch (error) {
      // if (error.data !== undefined) {
      //   addToast(error.data.message, { appearance: "error" });
      // } else {
        console.log(error);
        addToast(error, { appearance: "error" });
      // }
    }
  };

  

  function refreshPage() {
    window.location.reload();
  }

  useEffect(() => {
    setnumberOfEmployeesVistited(page * employeesPerPage);
  }, [page]);

  const totalPages = Math.ceil(employees.length / employeesPerPage);
  const changePage = ({ selected }) => {
    setPage(selected);
  };
  const [firstRender, setfirstRender] = useState(true);

  function PageLoadingMessage() {
    const [words, setWords] = useState(LOADER_WORDS);
    const [pendingPath, setPendingPath] = React.useState("");
    const showLoader = useSpinDelay(Boolean(status === "successfully"), {
      delay: 400,
      minDuration: 1000,
    });

    React.useEffect(() => {
      if (state === "loading") setWords(LOADER_WORDS);

      const interval = setInterval(() => {
        setWords(([LOADER_WORDS, ...rest]) => [...rest, LOADER_WORDS]);
      }, 2000);

      return () => clearInterval(interval);
    }, [pendingPath, state]);

    React.useEffect(() => {
      setfirstRender(false);
    }, []);

    const action = words[0];

    return (
      <NotificationMessage position="bottom-right" visible={showLoader}>
        <div className={clsx("flex items-center w-64")}>
          <motion.div
            className="absolute"
            transition={{ repeat: Infinity, duration: 2, ease: "linear" }}
            animate={{ rotate: 360 }}
          >
            <Circle size={56} color="UNKNOWN" />
          </motion.div>
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 1024 1024"
            className={clsx("inline w-6 ml-4")}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M864 736c0-111.6-65.4-208-160-252.9V317.3c0-15.1-5.3-29.7-15.1-41.2L536.5 95.4C530.1 87.8 521 84 512 84s-18.1 3.8-24.5 11.4L335.1 276.1a63.97 63.97 0 0 0-15.1 41.2v165.8C225.4 528 160 624.4 160 736h156.5c-2.3 7.2-3.5 15-3.5 23.8 0 22.1 7.6 43.7 21.4 60.8a97.2 97.2 0 0 0 43.1 30.6c23.1 54 75.6 88.8 134.5 88.8 29.1 0 57.3-8.6 81.4-24.8 23.6-15.8 41.9-37.9 53-64a97 97 0 0 0 43.1-30.5 97.52 97.52 0 0 0 21.4-60.8c0-8.4-1.1-16.4-3.1-23.8L864 736zM512 352a48.01 48.01 0 0 1 0 96 48.01 48.01 0 0 1 0-96zm116.1 432.2c-5.2 3-11.2 4.2-17.1 3.4l-19.5-2.4-2.8 19.4c-5.4 37.9-38.4 66.5-76.7 66.5s-71.3-28.6-76.7-66.5l-2.8-19.5-19.5 2.5a27.7 27.7 0 0 1-17.1-3.5c-8.7-5-14.1-14.3-14.1-24.4 0-10.6 5.9-19.4 14.6-23.8h231.3c8.8 4.5 14.6 13.3 14.6 23.8-.1 10.2-5.5 19.6-14.2 24.5z"></path>
          </svg>

          <div className="inline-grid ml-10">
            <div className="flex col-start-1 row-start-1 overflow-hidden text-gray-700">
              <motion.span
                key={action}
                initial={{ y: 15, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -15, opacity: 0 }}
                transition={{ duration: 0.25 }}
              >
                {action}
              </motion.span>
            </div>
          </div>
        </div>
      </NotificationMessage>
    );
  }

  return (
    <>
      <div className="relative mx-10vw">
        <PageLoadingMessage />
        <div className="max-w-5xl mx-auto ">
          <div className="flex justify-end mt-8">
            <p vphawk className="sort-by-title mt-5">
              Sort by
            </p>
            <select
              id="location"
              name="location"
              className="text-white ml-2 w-28 px-2 h-12 bg-gray-100 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              onChange={(e) => setSearchLevel(e.target.value)}
              defaultValue=""
            >
              <option value="">Level</option>

              <option>Admin</option>
              <option>Academic</option>
              <option>Engineer</option>
              <option>Boss</option>
              <option>Robot</option>
            </select>
          </div>
          <div>
            <h3
              text-vd7
              className="title is-text-capitalized mb-3 mt-4 text-white"
            >
              Inventory: Crews
            </h3>
            <div className="z-20">
              {isApproveBuy?<button
                type="button"
                onClick={() => buy(0)}
                className="title mb-3 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-purple-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                MINT{" "}
               20{" "}
              USD
              </button>:
              <button
              type="button"
              onClick={() => buyApproveHandle()}
              className="title mb-3 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-purple-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Approve MINT
            </button>
              }
              <ModalFeesAllHuman
                id={1}
                gameObjets={people}
                selected={state}
                resetCheckedBoxHandle={resetCheckedBoxHandle}
              />
              {/* <ModalFeesAll
                gameObjets={people}
                selected={state}
                resetCheckedBoxHandle={resetCheckedBoxHandle}
              /> */}
            </div>
            <hr data-v-0833e527 text-vd7 className="horizontal light" />
            <p text-vd7 className="mt-2">
              You have{" "}
              {`${generalData.humanavailable}/${generalData.totalHuman}  `} NFTs{" "}
              <span style={{ marginLeft: "20px" }}>
                Total EXP:{" "}
                {`${generalData.expAvailable}/${generalData.expTotal}`}
              </span>
            </p>
          </div>
          <div>
            {loading && employees.length == 0 ? (
              <div class="flex justify-center items-center mt-20">
                <div class="animate-spin rounded-full h-36 w-36 border-t-2 border-b-2 border-white"></div>
              </div>
            ) : null}
            {!loading && employees.length == 0 ? (
              <>
                <div class="flex justify-center items-center mt-20">
                  <div>
                    <h1 className="text-white">DATA NO FOUND</h1>
                  </div>
                </div>

                <div class="flex justify-center items-center mt-20">
                  <button
                    onClick={refreshPage}
                    type="button"
                    className=" px-2.5 py-1.5 border border-transparent text-xl font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Reload
                  </button>
                </div>
              </>
            ) : null}
            <div
              className={`grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3`}
            >
             {mistery === null ? null : mistery}
              {employees.length > 0
                ? employees
                    .slice(
                      numberOfEmployeesVistited,
                      numberOfEmployeesVistited + employeesPerPage
                    )
                    .filter((employeesPerPage) => {
                      if (searchLevel === "") {
                        return employeesPerPage;
                      } else if (
                        employeesPerPage.level
                          .toLowerCase()
                          .includes(searchLevel.toLowerCase())
                      ) {
                        return employeesPerPage;
                      }
                    })
                    .filter((person, i) => {
                      if (searchExp === "") {
                        return person;
                      } else if (
                        person.exp
                          .toLowerCase()
                          .includes(searchExp.toLowerCase())
                      ) {
                        return person;
                      }
                    })
                    .map((person, idx) => (
                      <HumanCard
                        key={idx}
                        employees={employees}
                        checkedBoxHandle={checkedBoxHandle}
                        person={person}
                      />
                    ))
                : null}
            </div>
            <div className="mt-20">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={totalPages}
                onPageChange={changePage}
                containerClassName={"navigationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"navigationDisabled"}
                activeClassName={"navigationActive"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Human;
