import React, { useState, useContext,useEffect } from "react";
import Web3Context from "../../context/Web3Context";
import InventoryContext from "../../context/Inventory";
import { UseGame, UseHuman } from "../../hooks/useContracts";
import clsx from "clsx";
import ModalGift from "./ModalGift";
import ModalSell from "./ModalSell";
import { useToasts } from "react-toast-notifications";
import { utils } from "ethers";
import UseCheckBook from "../../hooks/UseCheckBook";
import ButtonPopoverHuman from "./ButtonPopoverHuman";
import ModalFeesAllRocket from "./ModallFeesAllRocket";
import { NotificationMessage } from "../../components/NotificationMessage/NotificationMessage";
import { Circle } from "../../components/Circle";
import { motion } from "framer-motion";
import { useSpinDelay } from "spin-delay";
import ModalInfoDead from "./ModalInfoDead";
import { DateTime } from "luxon";
import ImgS3Ximbia from "../../components/ImgS3Ximbia";

const LOADER_WORDS = [
  "Loading...",
  "Checking cdn",
  "Checking cache",
  "Fetching from BSC Network",
  "Updating cache",
  "Transfer",
];

function RocketCard({ person }) {
  const Game = UseGame();
  const Human = UseHuman();
  const { addToast } = useToasts();
  const { spaceShip, generalData, updateHandle } = useContext(InventoryContext);
  const [state, checkedBoxHandle, resetCheckedBoxHandle] = UseCheckBook();
  const { accounts, isLoaded, setupdate, update } = useContext(Web3Context);
  const [searchLevel, setSearchLevel] = useState("");
  const [status, setStatus] = useState(null);
  const [humanPayFeesArrayUSD, sethumanPayFeesArrayUSDl] = useState([
    6, 8, 12, 20, 30,
  ]);
  const feeDays = [30, 30, 30, 15, 15];
  const [time, settime] = useState(0);


  useEffect(() => {    
    if(person.lastDate==undefined || person.lastDate==0) return;
    if(person.id == 0 || person.id == undefined || person.id > 5) return;
    const newTime = DateTime.fromSeconds(Number(person.lastDate)).plus({
      day: feeDays[person.id - 1],
    });
    const time_ = DateTime.now()
  //  console.log(time_.toFormat('yy LL dd'),newTime.toFormat('yy LL dd')  );  
  const dif =  newTime - time_
  if(dif >0){
    const value_ = newTime.diff(time_, ["days"]).toObject().days.toFixed(0);
    settime(value_)

}    return () => {
    
    };
  }, [person]);
  const dead = async (tokenId) => {
    try {
      const [load, contract] = await Human;
      const res = await contract["safeTransferFrom(address,address,uint256)"](
        accounts,
        "0x000000000000000000000000000000000000dEaD",
        tokenId
      );
      addToast("Dead success", { appearance: "success" });
      setStatus("successfully");
      res.wait().then(() => {
        setStatus("Complete");
        updateHandle();
      });
    } catch (error) {
      if (error.data.message !== undefined) {
        addToast(error.data.message, { appearance: "error" });
      } else {
        addToast(error.message, { appearance: "error" });
      }
    }
  };

  const transfer = async (tokenId, to) => {
    try {
      const [load, contract] = await Human;
      const res = await contract["safeTransferFrom(address,address,uint256)"](
        accounts,
        to,
        tokenId.toString()
      );
      addToast("tranfer success", { appearance: "success" });
      setStatus("successfully");
      res.wait().then(() => {
        setStatus("Complete");
        updateHandle();
      });
    } catch (error) {
      if (error.data.message !== undefined) {
        addToast(error.data.message, { appearance: "error" });
      } else {
        addToast(error.message, { appearance: "error" });
      }
    }
  };

  return (
    <>
 <div estin_ text-vd7  className={clsx("relative__hb_hu ")} style={{display: 'block'}}>
        <div className="card crd admin ">
          <div className="card-body">
           
            
            <div className="group peer relative block w-full focus:outline-none">
            <div 
             className={clsx(
              "card-rocket-1 aspect-h-3 aspect-w-3 rounded-lg bg-gray-500 cursor-pointer video hover:ring-2 hover:ring-offset-2 hover:ring-primary-500",
              {
                "card-rocket-1 ": person.name === 1,
                "card-rocket-2 ": person.name === 2,
                "card-rocket-3 ": person.name === 3,
                "card-rocket-4 ": person.name === 4,
                "card-rocket-5 ": person.name === 5,
              }
            )}
            >
              <ImgS3Ximbia
                 alt={`human-${person.id}`}
                 src={`${person.previewUrl}`}
                className="video hover:bg-blend-darken shadow-black focus-ring w-full rounded-lg object-cover object-center transition transition-opacity"
              />
            </div>
          </div>
          <div estin_ className="card-bottom-data " style={{paddingLeft: '8px', paddingRight: '8px'}}>
              <div estin_>
                <p estin_ className="level-txth mt-2"><span estin_ className="is-text-regular is-color-lightGrey"></span>#{person.tokenID}<span estin_ className="is-text-regular ranft"></span></p>
              </div>
            </div>
            <div className="crd-taginf clearfix"><span className="crd-tag">Class: {person.class}
              </span><span className="crd-tag" style={{marginLeft: '8px'}}>SEATS: {person.seat}</span>
            </div>
            <div estin_ className="card-bottom-data mt-4" style={{paddingLeft: '8px', paddingRight: '8px'}}>
              <div estin_>
                <p estin_ className="level-txth"><span estin_ className="is-text-regular is-color-lightGrey">Remaining fee: </span> <span estin_ className="is-text-regular ranft">{person.remainingFee} Days</span></p>
              </div>
            </div>
            <div className="absolute z-10 left-2 top-12 p-1 w-4 h-4   focus:outline-none  group-hover:opacity-100 peer-hover:opacity-100 hover:opacity-100 peer-focus:opacity-100 focus:opacity-100 transition   lg:opacity-0  absolute z-10 ">
            <span className="relative z-0 shadow-sm rounded-md w-40  ">
              <div className="multi-button">
                <ModalInfoDead
                  onClick={() => {
                    dead(person.tokenID);
                  }}
                />
                <ModalSell
                  transfer={transfer}
                  id={person.tokenID}
                  isDead={person.dead}
                />
                <ModalGift
                  transfer={transfer}
                  tokenID={person.tokenID}
                  id={person.tokenID}
                  isDead={person.dead}
                />
              </div>
            </span>
          </div>

          {person.exhausted && (
            <div className="absolute z-10 left-24 top-4 text-xl p-1 w-40 h-4 font-bold  focus:outline-none   absolute z-10 ">
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
              Need maintenance
              </span>
            </div>
          )}
          {person.dead && (
            <div className="absolute z-10 left-2 top-4 text-xl p-1 w-20 h-4 font-bold  focus:outline-none    absolute z-10 ">
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                Dead
              </span>
            </div>
          )}



          </div>
        
        </div>
      </div>
    </>
  );
}

export default RocketCard;
