import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {utils} from "ethers"

export default function ModalGift({ gift,transfer,id }) {

  const [walletGift, setWalletGift] = useState("");
   

  const [open, setOpen] = useState(false);
  const [active, setactive] = useState(false)

  const cancelButtonRef = useRef(null);
  const onClickHandle=async()=>{ 
    await transfer(id,walletGift)    
  }

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        type="button"
        className="  border border-gray-300 bg-indigo-600 hover:bg-white text-sm font-medium text-white hover:text-indigo-800 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
          />
        </svg>
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
          style={{ zIndex: "99999999" }}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-[#14181d] rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="">
                  <div className="">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-700 text-center"
                    >
                      Gift
                    </Dialog.Title>
                    <div className="flex justify-center mt-2">
                      <div>
                        <input
                          type="text"
                          name="wallet"
                          id="wallet"
                          className="bg-[#2f3740] shadow-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Wallet"
                          onChange={(e) =>{ 
                          if(utils.isAddress(e.target.value)){
                            setactive(true)
                            setWalletGift(e.target.value)
                          }else{
                            setactive(false)
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex justify-center">
                  <button
                    type="button"
                    disabled={!active}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#437bc16e] text-base font-medium text-[#34a1ff]  sm:w-auto sm:text-sm"
                    onClick={() => {
                      onClickHandle()
                      setOpen(false)}}
                  >
                    Send
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
