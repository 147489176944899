import * as React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHotkeys } from "react-hotkeys-hook";
import { useClickAway } from "react-use";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import ImgS3 from "../../components/ImgS3";

const ModalScan = ({ passenger, scan, open_, setScan, data }) => {
  const [open, setOpen] = React.useState(true);
  //const [scan, setScan] = React.useState(true);
  /* forms */
  /* forms-end */

  React.useEffect(() => {
    //setOpen(open_)
    console.log(open_);
    return () => {};
  }, [open_]);
  const ref = React.useRef(null);
  const boxRef = React.useRef(null);
  useHotkeys("cmd+k", () => setOpen(true));
  useHotkeys("ctrl+k", () => setOpen(true));
  useHotkeys("esc", () => setOpen(false));
  useClickAway(boxRef, () => setOpen(false));

  return (
    <>
      <Transition.Root as={React.Fragment} show={open}>
        <Dialog
          as="div"
          className="overflow-y-auto fixed inset-0 z-50"
          initialFocus={ref}
          onClose={setOpen}
          open={open}
          static
          style={{ zIndex: "99999999" }}
        >
          <div className="flex justify-center items-center px-4 pt-4 pb-20 min-h-screen text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#181c20] bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              aria-hidden="true"
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-purple-grandient rounded-lg px-0 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="transition-all transform">
                  <div className="" ref={boxRef}>
                    <div className="hidden sm:block absolute top-0 right-0 pr-4">
                      <button
                        type="button"
                        className=" rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div
                      className="swal2-html-container"
                      id="swal2-html-container"
                    >
                      <div className="img_succes_xp">
                        {" "}
                        <ImgS3 src="/astr.png" alt="" />
                      </div>
                      <div className="cont_succes">
                        <div className="Succes">
                          {" "}
                          <span className="succ_sp">
                            <ImgS3
                              src={`/images/game/assets/pastel.png`}
                              alt=""
                            />
                            <p>
                              <b>Successful scanning!</b>
                            </p>
                          </span>
                        </div>
                        <div className="crypt">
                          <span className="crypt_cou">
                            <ImgS3
                              src={`/images/game/assets/usd_reward.png`}
                              alt=""
                            />
                            <p>{data}</p>
                          </span>

                        </div>
                        
                   
                        {/*
                          <div className="roll">
                          <span className="roll_sp">
                            <ImgS3 src={`/images/game/assets/winrte.png`} alt="" />
                            <p>You roll: 54.6</p>
                          </span>
                        </div>
                        <div className="need_b">
                          <span className="need_sp">
                            <ImgS3 src={`/images/game/assets/winrate.png`} alt="" />
                            <p>Needed: 65.0 or below</p>
                          </span>
                        </div>
                        
                        
                        */}
                      </div>
                      <div className="-mt-28">
                        <h4>Curious fact:</h4>
                          <p> Galaxies are real monsters between 3,000 and 300,000 light-years in diameter, separated by even greater enormous distances. But the Universe is so gigantic that our Milky Way could be just 1 of the 2,000,000,000,000 galaxies.</p>
                          </div>
                    </div>

                    {/*
  {scan === false && (
                      <>
                        <div className="hidden sm:block absolute top-0 right-0 pr-4">
                          <button
                            type="button"
                            className=" rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                        <div
                          class="swal2-html-container"
                          id="swal2-html-container"
                        >
                          <div class="img_succes_xp mb-3">
                            {" "}
                            <img src="/lose.svg" alt="" />
                          </div>
                          <div class="">
                            <div class="Lose bg-red-200 rounded-full">
                              {" "}
                              <span class="lose_sp">
                                <XCircleIcon
                                  className="h-5 w-5 text-red-700"
                                  aria-hidden="true"
                                />
                                <p>
                                  <b>Lose scanning!</b>
                                </p>
                              </span>
                            </div>
                           
                        
                          </div>
                        </div>
                      </>
                    )}

*/}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default ModalScan;
