import React from 'react';

export default function ImgS3Ximbia({
  src,
  alt,
  className = '',
  ...props
}) {
  return (
    <>
      <img
        loading="lazy"
        src={`https://s3.magic-api.xyz/ximbia${src}`}
        alt={alt || ''}
        sizes="100vw"
        className={className}
        {...props}
      />
    </>
  );
}

export const getS3XimbiaUrl = (src) => {
  return `https://s3.magic-api.xyz/ximbia${src}`;
};
