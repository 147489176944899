import React, { useContext, useEffect, useState } from "react";
import "../../css/game/wactus.css";
import "../../css/game/stplay.css";
import { PlanetData } from "../../data/PlanetData";
import InventoryContext from "../../context/Inventory";
import SlidePlay from "./SlidePlay";
import ImgS3 from "../../components/ImgS3";
import { UseGame } from "../../hooks/useContracts";
import { useToasts } from "react-toast-notifications";
//import { useCountdown } from '../../hooks/useCountdown';
import StabHandle, {Win ,Loser, Withdraw, Timer } from "./PlayHandle/Stabhandle";
import ImgS3Ximbia from "../../components/ImgS3Ximbia";

function PlanetCard({
  status,
  setStatus,
  notification,
  setNotification,
  isStatus,
  setIsStatus,
  planet
}) {
  return (    
        
                    <li >
                      <a className="vplanet">
                        <div className="nump_tag">
                          <div className="pl-badge tag_number">{planet.id}</div>
                        </div>
                        <ImgS3Ximbia
                          src={planet.img}
                          className="card__image"
                          alt=""
                        />
                        <div className="vp__overlay">
                          <div className="vp__header">
                            <div className="start-sc">
                              <SlidePlay
                                setIsStatus={setIsStatus}
                                isStatus={isStatus}
                                planet={planet}
                                status={status}
                                setStatus={setStatus}
                                notification={notification}
                                setNotification={setNotification}
                              />
                            </div>
                            <svg
                              className="vp__arc"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path></path>
                            </svg>
                            <ImgS3
                              className="vp__thumb"
                              src={`/images/game/assets/satellite.png`}
                              alt=""
                            />
                            <div className="vp__header-text">
                              <h3 className="vp__title">{planet.title}</h3>
                              <span className="vp__wrate">
                                Winrate: {planet.winrate}%
                              </span>
                            </div>
                          </div>
                          <div className="exp-info">
                            <div className="exp-badge me-2">
                              <p>Required EXP</p>
                              <p>{planet.exp}</p>
                            </div>
                            <div className="exp-badge">
                              <p>Earn</p>
                              <p>{planet.earn} USD</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>              
               
  );
}

export default PlanetCard;
