import React, { useEffect, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHotkeys } from "react-hotkeys-hook";
import { useClickAway } from "react-use";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import { Tab } from "@headlessui/react";
import LevelHuman from "./LevelHuman";
import ExpHuman from "./ExpHuman";
//import { UseGame, UseHuman } from "../../hooks/useContracts";
import { motion, AnimatePresence } from "framer-motion";
import { LevelUpGoLevel } from "./LevelUpGoLevel";
import { UseFuse } from "../../../hooks/useContracts";
import { useToasts } from "react-toast-notifications";
import TokenContex from "../../../context/TokenHandle";
import Web3Context from "../../../context/Web3Context";
import './modal.box.css'
import ImgS3 from "../../../components/ImgS3";

const listingsData_ = {
  dead: false,
  exhausted: true,
  exp: 59,
  id: 1,
  imageIcoUrl: "/images/marketplace/human/icon1.png",
  imageUrl: "/images/game/nfts/human/chief.png",
  level: "Admin",
  name: "ASTRONAUT 1",
  previewUrl: "/videos/h-1.png",
  tokenID: 694,
  usage: "99908",
  videoUrl: "/videos/h-1.mp4",
  remainingFee: 0,
};

const Approve_ = ({ gameApproveHandle }) => (
  <button
    type="button"
    onClick={(e) => gameApproveHandle(1)}
    className="btn btn-primary"
  >
    Approve SSAP
  </button>
);

const ApproveNFT_ = ({ gameApproveHandle }) => (
  <button
    type="button"
    onClick={(e) => gameApproveHandle(1)}
    className="btn btn-primary"
  >
    Approve NFT Burn
  </button>
);

const ApproveHandle = ({
  fuseApproveHandle,
  gameApproveHandle,
  isApproveNftFuse,
  isApproveFuse,
}) => {
  const [approve, setapprove] = useState(null);
  useEffect(() => {
    if (!isApproveFuse)
      setapprove(<Approve_ gameApproveHandle={gameApproveHandle} />);
    if (isApproveFuse && !isApproveNftFuse)
      setapprove(<ApproveNFT_ gameApproveHandle={fuseApproveHandle} />);
    return () => {};
  }, [isApproveNftFuse, isApproveFuse]);

  return <>{approve}</>;
};

const LevelUp = ({ employees, scan, open_, setScan, person }) => {
  const {
    gameApproveHandle,
    balanceOf,
    isApprove,
    fuseApproveHandle,
    isApproveFuse,
    isApproveNftFuse,
  } = useContext(TokenContex);
  const { accounts, isLoaded, setupdate, update } = useContext(Web3Context);
  const { addToast } = useToasts();
  const Fuse = UseFuse();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("select");
  const [active, setActive] = React.useState(0);
  const [type_, settype_] = React.useState(0);
  const [data, setpersonData] = useState(listingsData_);
  const [cost, setCost] = useState([20, 40, 60]);

  //const [scan, setScan] = React.useState(true);
  /* forms */
  /* forms-end */
  useEffect(() => {
    setpersonData(person);
    return () => {};
  }, [person]);

  const fuseHandle = async (tokenId) => {
    try {
      console.log(tokenId);
      //levelUp(uint[] memory tokenId, uint level)
      const [load, contract] = await Fuse;
      const res = await contract.levelUp([tokenId], person.id);
      addToast("LevelUp success", { appearance: "success" });
      setStatus("successfully");
      res.wait().then(() => {
        setStatus("LevelUp Complete");
        setupdate();
      });
    } catch (error) {
      if (error.data != undefined) {
        addToast(error.data.message, { appearance: "error" });
      } else {
        addToast(error.message, { appearance: "error" });
      }
    }
  };

  React.useEffect(() => {
    //setOpen(open_)
    console.log(open_);
    return () => {};
  }, [open_]);
  const ref = React.useRef(null);
  const boxRef = React.useRef(null);
  useHotkeys("cmd+k", () => setOpen(true));
  useHotkeys("ctrl+k", () => setOpen(true));
  useHotkeys("esc", () => setOpen(false));
  useClickAway(boxRef, () => setOpen(false));

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className=" px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Level UP
      </button>
      <Transition.Root as={React.Fragment} show={open}>
        <Dialog
          as="div"
          className="overflow-y-auto fixed inset-0 z-50 "
          onClose={setOpen}
          open={open}
          static
          style={{ zIndex: "99999999" }}
        >
          <div className="flex justify-center items-end px-4 pt-4 pb-20 min-h-screen  text-center sm:block sm:p-0 lg:-mt-32 -mt-4">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#181c20] bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              aria-hidden="true"
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-purple-grandient border border-indigo-500 max-w-2xl   rounded-lg px-0 pt-5 pb-4 overflow-y shadow-xl transform transition-all sm:my-40 sm:align-middle sm:w-full sm:p-6 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
          
                <div className="transition-all transform">
                  <div className="">
                    {status === "select" && (
<>
<div className="">
<div className="">
  <div className="">
    <div className="py-lg-5 px-lg-15">
      <div className="w-100">
        <div className="text-center">
          <h1 className="mb-3 flex justify-center text-white">Improve NFT, Crew</h1>
          <div className="text-muted fw-bold fs-5">
            Level:{" "}
            <a className="link-primary fw-bolder">
            {data.name}
            </a>
          </div>
          <div className="cad">
            <ImgS3 src={data.imageIcoUrl} />
          </div>
        </div>
        <div className="fv-row">
          <label className="flex flex-stack mb-5 cursor-pointer">
            <span className="flex align-items-center me-2">
              <span className="cad me-6">
                <span className="cad-label bg-light-primary">
                  <span className="hw-icon hw-icon-1">
                    <img src="https://human-world.s3.amazonaws.com/asset/xp.png" alt="" />
                  </span>
                </span>
              </span>
              <span className="flex flex-column text-white">
                <span className="fw-bolder fs-6">
                  Experience
                </span>
              </span>
            </span>
            <span className="op-check op-check-custom op-check-solid">
            <input
                                                  id={`side-${data.id}`}
                                                  name="option"
                                                  type="radio"
                                                  checked={type_ === 0}
                                                  onClick={() => {
                                                    setActive(0);
                                                    settype_(0);
                                                  }}
                                                  className="op-check-input"
                                                />
            </span>
          </label>
          <label className="flex flex-stack mb-5 cursor-pointer">
            <span className="flex align-items-center me-2">
              <span className="cad me-6">
                <span className="cad-label bg-light-primary">
                  <span className="hw-icon hw-icon-1">
                    <ImgS3 src="/lvlup.png" />
                  </span>
                </span>
              </span>
              <span className="flex flex-column text-white">
                <span className="fw-bolder fs-6">
                  Level Up (Min)
                </span>
              </span>
            </span>
            <span className="op-check op-check-custom op-check-solid">
            <input
                                                  id={`side-${data.id}`}
                                                  name="option"
                                                  type="radio"
                                                  checked={type_ === 1}
                                                  onClick={() => {
                                                    setActive(1);
                                                    settype_(1);
                                                  }}
                                                  className="op-check-input"
                                                />
            </span>
          </label>
          <label className="flex flex-stack mb-5 cursor-pointer">
            <span className="flex align-items-center me-2">
              <span className="cad me-6">
                <span className="cad-label bg-light-primary">
                  <span className="hw-icon hw-icon-1">
                  <ImgS3 src="/lvlup.png" />
                  </span>
                </span>
              </span>
              <span className="flex flex-column text-white">
                <span className="fw-bolder fs-6">
                  Level Up (Max)
                </span>
              </span>
            </span>
            <span className="op-check op-check-custom op-check-solid">
            <input
                                                  id={`side-${data.id}`}
                                                  name="option"
                                                  type="radio"
                                                  checked={type_ === 2}
                                                  onClick={() => {
                                                    setActive(1);
                                                    settype_(2);
                                                  }}
                                                  className="op-check-input"
                                                />

            </span>
          </label>
        </div>
   

      </div>
      <div className="box-footer flex-center">
      <ApproveHandle
                          fuseApproveHandle={fuseApproveHandle}
                          gameApproveHandle={gameApproveHandle}
                          isApproveNftFuse={isApproveNftFuse}
                          isApproveFuse={isApproveFuse}
                        />
                        {active === 1 &&
                          isApproveFuse &&
                          isApproveNftFuse &&
                          person.id != 5 && (
                            <button
                              type="button"
                              onClick={() => setStatus("list")}
                              className="btn btn-primary"
                            >
                              Go Level
                            </button>
                          )}
                        {active === 0 && isApproveFuse && isApproveNftFuse && (
                          <button
                            type="button"
                            onClick={(e) => fuseHandle(data.tokenID)}
                            className="btn btn-primary"
                          >
                            Go Experience
                          </button>
                        )}
      </div>
    </div>
  </div>
</div>
</div>
                      </>
                    )}

                    {status === "list" && (
                      <LevelUpGoLevel
                        setOpen={setOpen}
                        setStatus={setStatus}
                        people={employees}
                        type_={type_}
                        personUp={person}
                        setupdate={setupdate}
                      />
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default LevelUp;
