import React from "react";
import ReactDOM from "react-dom";
import { ToastProvider } from "react-toast-notifications";
import "./index.css";
import App from "./App";
import { Web3Provider } from "./context/Web3Context";
import { TokenProvider } from "./context/TokenHandle";
import { InventoryProvider } from "./context/Inventory";
import { MarketplaceProvider } from "./context/MarketplaceContext";
import { MarketplaceProviderU } from "./context/MarketplaceContextU";


import reportWebVitals from "./reportWebVitals";

const MyCustomToast = ({ appearance, children }) => (
  <div style={{ background: appearance === "error" ? "red" : "green" }}>
    {children}
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider autoDismiss autoDismissTimeout={3000}>
    <Web3Provider>
      <TokenProvider>
        <InventoryProvider>
        <MarketplaceProvider>
        <MarketplaceProviderU>
            <App />
        </MarketplaceProviderU>
          </MarketplaceProvider>          
        </InventoryProvider>
      </TokenProvider>
    </Web3Provider>
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

