export const PlanetData = [
  {
    id: 1,
    img: "/planets/1.jpg",
    title: "To the Moon",
    winrate: 90,
    exp: 150,
    earn: 3,
  },
  {
    id: 2,
    img: "/planets/2.jpg",
    title: "Venus",
    winrate: 88,
    exp: 300,
    earn: 6,
  },
  {
    id: 3,
    img: "/planets/3.jpg",
    title: "Mercury",
    winrate: 85,
    exp: 450,
    earn: 8,
  },
  {
    id: 4,
    img: "/planets/4.jpg",
    title: "Mars",
    winrate: 82,
    exp: 600,
    earn: 12,
  },
  {
    id: 5,
    img: "/planets/5.jpg",
    title: "Jupiter",
    winrate: 80,
    exp: 750,
    earn: 18,
  },
  {
    id: 6,
    img: "/planets/6.jpg",
    title: "Saturn",
    winrate: 78,
    exp: 900,
    earn: 25,
  },
  {
    id: 7,
    img: "/planets/7.jpg",
    title: "Uranus",
    winrate: 75,
    exp: 1050,
    earn: 30,
  },
  {
    id: 8,
    img: "/planets/8.jpg",
    title: "Neptune",
    winrate: 72,
    exp: 1200,
    earn: 35,
  },
  {
    id: 9,
    img: "/planets/9.jpg",
    title: "HD 209458 b",
    winrate: 70,
    exp: 1350,
    earn: 40,
  },
  {
    id: 10,
    img: "/planets/10.jpg",
    title: "J1407b",
    winrate: 66,
    exp: 1500,
    earn: 45,
  },
  {
    id: 11,
    img: "/planets/11.jpg",
    title: "PSR B1620-26 b",
    winrate: 64,
    exp: 1650,
    earn:50 ,
  },
  {
    id: 12,
    img: "/planets/12.jpg",
    title: "Kepler-1b",
    winrate: 62,
    exp: 1800,
    earn: 55,
  },
  {
    id: 13,
    img: "/planets/13.jpg",
    title: "COROT-7b",
    winrate: 62,
    exp: 1950,
    earn:60,
  },
  {
    id: 14,
    img: "/planets/14.jpg",
    title: "55 CANCRI-e",
    winrate: 60,
    exp: 2100,
    earn: 65,
  },
  {
    id: 15,
    img: "/planets/15.jpg",
    title: "HAT-P-7b",
    winrate: 58,
    exp: 2250,
    earn: 68,
  },
  {
    id: 16,
    img: "/planets/16.jpg",
    title: "HAT-P-7b",
    winrate: 56,
    exp: 2400,
    earn: 75,
  },
  {
    id: 17,
    img: "/planets/17.jpg",
    title: "KEPLER 438b",
    winrate: 54,
    exp: 2550,
    earn:80,
  },
  {
    id: 18,
    img: "/planets/18.jpg",
    title: "HD 106906 b",
    winrate: 54,
    exp: 2700,
    earn:90,
  },
  {
    id: 19,
    img: "/planets/19.jpg",
    title: "KEPLER 78b",
    winrate: 52,
    exp: 2850,
    earn: 95,
  },
  {
    id: 20,
    img: "/planets/20.jpg",
    title: "HD 80606-B",
    winrate: 52,
    exp: 3000,
    earn: 100,
  },
  {
    id: 21,
    img: "/planets/21.jpg",
    title: "KEPLER-440b",
    winrate: 50,
    exp: 3150,
    earn: 110,
  },
  {
    id: 22,
    img: "/planets/22.jpg",
    title: "GJ 1214 b",
    winrate: 50,
    exp: 3300,
    earn: 130,
  },
  {
    id: 23,
    img: "/planets/23.jpg",
    title: "NGTS-1b",
    winrate: 50,
    exp: 3450,
    earn: 140,
  },
  {
    id: 24,
    img: "/planets/24.jpg",
    title: "OGLE-BLG-390L",
    winrate: 50,
    exp: 3600,
    earn: 150,
  },
  {
    id: 25,
    img: "/planets/25.jpg",
    title: "WASP-12b",
    winrate: 50,
    exp: 3750,
    earn: 170,
  },
  {
    id: 26,
    img: "/planets/26.jpg",
    title: "GLIESE 436b",
    winrate: 50,
    exp: 3900,
    earn: 190,
  },
  {
    id: 27,
    img: "/planets/27.jpg",
    title: "Saffar",
    winrate: 50,
    exp: 4050,
    earn: 200,
  },
  {
    id: 28,
    img: "/planets/28.jpg",
    title: "KIC 12557548 b",
    winrate: 50,
    exp: 4200,
    earn: 230,
  },
  {
    id: 29,
    img: "/planets/29.jpg",
    title: "Random",
    winrate: 50,
    exp: 4350,
    earn:250,
  },

    {
      id: 30,
      img: "/planets/30.jpg",
      title: "Random",
      winrate: 50,
      exp: 4500,
      earn: 260,
    },
    {
      id: 31,
      img: "/planets/31.jpg",
      title: "Sol",
      winrate: 54,
      exp: 6000,
      earn: 300,
    },
    {
      id: 32,
      img: "/planets/32.jpg",
      title: "Galaxy UEX",
      winrate: 35,
      exp: 7500,
      earn: 400,
    },
  ];