import React,{useEffect,useState} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHotkeys } from "react-hotkeys-hook";
import { useClickAway } from "react-use";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import { Tab } from "@headlessui/react";
import LevelHuman from "./LevelHuman";
import ExpHuman from "./ExpHuman";
import { motion, AnimatePresence } from "framer-motion";
import { UseFuse } from "../../../hooks/useContracts";
import { useToasts } from "react-toast-notifications";
import Checkbox from "../../../components/Checkbox";
import UseCheckBook from "../../../hooks/UseCheckBook";
import { constants, BigNumber, ethers } from "ethers";
import ImgS3 from "../../../components/ImgS3";

const listingsData_ =   [{
dead: false,
exhausted: true,
exp: 59,
id: 1,
imageIcoUrl: "/images/marketplace/human/icon1.png",
imageUrl: "/images/game/nfts/human/chief.png",
level: "Admin",
name: "ASTRONAUT 1",
previewUrl: "/videos/h-1.png",
tokenID: 694,
usage: "99908",
videoUrl: "/videos/h-1.mp4",
remainingFee: 0
  }]
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const a ={level: "Engineer",
exp: 1310,
imageUrl: "/images/marketplace/human/icon3.png",}

export const LevelUpGoLevel = ({ setOpen,setStatus, people,personUp ,setupdate, type_}) => {  
  
  const { addToast } = useToasts();
  const Fuse=UseFuse()
  const [data, setpersonData] = useState(listingsData_);
  const [state, checkedBoxHandle, resetCheckedBoxHandle] = UseCheckBook();

  const [available, setavailable] = useState(true);
  const [availableNumber, setavailableNumber] = useState(0)
  const [tokenIds, settokenIds] = useState([]);


  const boxRef = React.useRef(null);
  useHotkeys("cmd+k", () => setOpen(true));
  useHotkeys("ctrl+k", () => setOpen(true));
  useHotkeys("esc", () => setOpen(false));
  useClickAway(boxRef, () => setOpen(false));
  
  useEffect(() => {    
     let people_ =people.filter(value=>personUp.id==value.id)
     console.log(people_);
    setpersonData(people_)
    return () => {    
    };
  }, [people]);


  useEffect(() => {    
    const check_ = Array.from(state)
    if(personUp.id==1 ||personUp.id==2){
   if(check_.length == 3 || check_.length >4)
   setavailable(false)
   else setavailable(true)
  }
  if(personUp.id==3 ||personUp.id==4){
    if( check_.length >3)
    setavailable(false)
    else setavailable(true)
   }
   return () => {    
   };
 }, [state]);



  const fuseHandle = async () => {
    const check_ = Array.from(state)
     console.log(check_, personUp.id)
    try {
      

     
      //levelUp(uint[] memory tokenId, uint level)
      const [load, contract] = await Fuse;
      let estimateGas = await contract.estimateGas.levelUp(check_, personUp.id)
      estimateGas = estimateGas.add(
        estimateGas.mul(BigNumber.from("10")).div("100")
      );

       const res = await contract.levelUp(check_, personUp.id,{ gasLimit: estimateGas })
        res.wait().then(() => {
        setStatus("LevelUp Complete"); 
        setupdate()        
        });
    } catch (error) {
      console.log(error)
      if (error.data != undefined) {
        addToast(error.data.message, { appearance: "error" });
      } else {
        addToast(error.message, { appearance: "error" });
      }
    }
  }; 
  const handleClick = (e, person) => {
    const check_ = Array.from(state)

console.log(personUp.id);
    if(personUp.id==1 ||personUp.id==2){
      if(check_.length <4)
      checkedBoxHandle(e.target.checked,person.tokenID,personUp.id)      
     }
     if(personUp.id==3 ||personUp.id==4){
       if( check_.length <3)
       checkedBoxHandle(e.target.checked,person.tokenID,personUp.id)       
      }

    
   
  };


  return (
    <>
                      <div className="inline-block align-bottom  px-4 pt-5 pb-4 text-left overflow-hidden  transform transition-all sm:my-8 sm:align-middle  sm:w-full sm:p-6">
                        {type_ === 1 && (
                          <p>Necesitas 1 </p>
                        ) || type_ === 2 && (
                          <p>Tipe 2</p>
                        )}
                        
                        <div>
                          <div className="flow-root mt-6">
                            <ul
                              role="list"
                              className="-my-5 divide-y divide-gray-200 h-40 overflow-y-auto"
                            >
                              {data.map((person) => (
                                <li key={person.handle} className="py-4">
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-0">
                                      <ImgS3
                                        className="h-8 w-8 rounded-lg"
                                        src={person.imageIcoUrl}
                                        alt=""
                                      />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-700 truncate">
                                        Level: {person.level}
                                      </p>
                                      <p className="text-sm text-gray-800 truncate">
                                        {person.exp + " EXP"}
                                      </p>
                                    </div>
                                    <div className="mr-6">
                                    <Checkbox
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded mr-6"
                                key={person.id}
                            type="checkbox"
          handleClick={(e) =>
            handleClick(e, person)
          }
          isChecked={state.has(person.tokenID)}
        />                                   
         </div>
         </div>

        
                                  
                                </li>                                
                              ))}
                            </ul>
                          </div>
                          <div className="mt-12">
                          {available?  <a
                              onClick={() => {
                                setOpen(false);
                                setStatus("select");
                                fuseHandle()
                              }}
                              className="w-full flex justify-center items-center px-4 py-2  shadow-sm text-sm font-medium rounded-md bg-blue-500 hover:bg-blue-600 transition-colors ease-in-out duration-200 text-white"
                            >
                              LevelUP{" "}
                            </a>:
                            <a                            
                            className="w-full flex justify-center items-center px-4 py-2  shadow-sm text-sm font-medium rounded-md bg-blue-500 hover:bg-blue-600 transition-colors ease-in-out duration-200 text-white"
                          >
                            Unavailable
                          </a>
                            }
                          </div>
                        </div>
                      </div>
                   
    </>
  );
};

