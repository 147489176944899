import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import { constants, BigNumber, ethers } from "ethers";
import UseCheckBook from "../../hooks/UseCheckBook";
import Web3Context from "../../context/Web3Context";
import InventoryContext from "../../context/Inventory";
import TokenHandle from "../../context/TokenHandle";
import {
  UseGame,
  UseHuman,
  useBuyHumanFactory,
} from "../../hooks/useContracts";
import ModalSell from "./ModalSell";
import ModalGift from "./ModalGift";
import { motion } from "framer-motion";
import ButtonPopoverHuman from "./ButtonPopoverHuman";
import { useToasts } from "react-toast-notifications";
import { utils } from "ethers";
import ModalFeesAll from "./ModalFeesAll";
import { NotificationMessage } from "../../components/NotificationMessage/NotificationMessage";
import { Circle } from "../../components/Circle";
import ReactPaginate from "react-paginate";
import LevelUp from "./LevelUp/LevelUp";
import { useSpinDelay } from "spin-delay";
import { DateTime } from "luxon";
import ModalInfoDead from "./ModalInfoDead";

function MisteryCard({ index, _type,text }) {
  const { addToast } = useToasts();
  const BuyHumanFactory = useBuyHumanFactory();
  const { accounts, isLoaded, setupdate, update } = useContext(Web3Context);
  const { people, generalData, updateHandle } = useContext(InventoryContext);
  const { setgameupdate } = useContext(TokenHandle);
  //const [state, setState] = useState(() => new Set());
  const [usage, setusage] = useState(0);
  const [time, settime] = useState(0);

  const claim = async () => {
    try {
      const [load, contract] = await BuyHumanFactory;
      let estimateGas = await contract.estimateGas.claimNFT(_type, index);
      estimateGas = estimateGas.add(
        estimateGas.mul(BigNumber.from("10")).div("100")
      );
      const res = await contract.claimNFT(_type, index, {
        gasLimit: estimateGas,
        value: utils.parseEther("0.0014"),
      });
      addToast("Claim success", { appearance: "success" });
      res.wait().then(() => {
        updateHandle();
        setgameupdate();
      });
    } catch (error) {
      if (error.data !== undefined) {
        addToast(error.data.message, { appearance: "error" });
      } else {
        addToast(error.message, { appearance: "error" });
      }
    }
  };

  return (
    <>
      <div
        estin_
        text-vd7
        className={clsx("relative__hb_hu ")}
        style={{ display: "block" }}
      >
        <div className={clsx("card crd admin")}>
          <div className="card-body">
            <div className="group peer relative block w-full focus:outline-none">
              <div className="aspect-h-3 aspect-w-3 rounded-lg bg-gray-500 cursor-pointer video hover:ring-2 hover:ring-offset-2 hover:ring-primary-500">
                <video
                  onMouseOver={(event) => event.target.play()}
                  src={`https://img.search.brave.com/aF9SSvXdg-A9Pqa6hMRvH32uoLmZe9KWQ_D09lRmX-I/rs:fit:682:1024:1/g:ce/aHR0cHM6Ly9pLnBp/bmltZy5jb20vb3Jp/Z2luYWxzLzI2LzAw/L2VjLzI2MDBlY2Ni/NTZiYWY4YmM5OWY2/N2Q1ZDk5MGZjZjY4/LmpwZw`}
                  muted
                  preload="none"
                  poster={`https://img.search.brave.com/aF9SSvXdg-A9Pqa6hMRvH32uoLmZe9KWQ_D09lRmX-I/rs:fit:682:1024:1/g:ce/aHR0cHM6Ly9pLnBp/bmltZy5jb20vb3Jp/Z2luYWxzLzI2LzAw/L2VjLzI2MDBlY2Ni/NTZiYWY4YmM5OWY2/N2Q1ZDk5MGZjZjY4/LmpwZw`}
                  className="video hover:bg-blend-darken shadow-black focus-ring w-full rounded-lg object-cover object-center transition transition-opacity"
                />
              </div>
              <div className="crd-title cursor-pointer ">
                <h1 className="text-2xl text-center flex justify-center text-gray-700">{text} BOX</h1>
              <div className="flex justify-center mt-12">
                <button class="actb buy"   onClick={()=>claim()}>
                  <span className="text-2xl"
                    style={{
                      fontFamily: "Titles, sans-serif",
                      letterSpacing: "0.5pt",
                    }}
                  >
                    CLAIM
                  </span>
                </button>
              </div>
          </div>
            </div>
          </div>
        </div>
      </div>

    
    </>
  );
}

export default MisteryCard;
