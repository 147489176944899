const RocketImage = null
export const level = [
    {
      id: 1,
      name: "Admin",
      generyName: "ASTRONAUT 1",
      imageUrl: "/humans/human-1.png",
      videoUrl: "/humans/human-1.png",
      previewUrl: "/humans/human-1.png",
      imageIcoUrl: "/humans/human-1.png",
    },
    {
      id: 2,
      name: "Academic",
      generyName: "ASTRONAUT 2",
      imageUrl: "/humans/human-2.png",
      videoUrl: "/humans/human-2.png",
      previewUrl:"/humans/human-2.png",
      imageIcoUrl: "/humans/human-2.png",
    },
    {
      id: 3,
      name: "Engineer",
      generyName: "ASTRONAUT 3",
      imageUrl: "/humans/human-3.png",
      videoUrl: "/humans/human-3.png",
      previewUrl: "/humans/human-3.png",
      imageIcoUrl: "/humans/human-3.png",
    },
    {
      id: 4,
      name: "Boss",
      generyName: "ASTRONAUT 4",
      imageUrl: "/humans/human-4.png",
      videoUrl:"/humans/human-4.png",
      previewUrl: "/humans/human-4.png",
      imageIcoUrl: "/humans/human-4.png",
    },
    {
      id: 5,
      name: "Robot",
      generyName: "ASTRONAUT 5",
      imageUrl:"/humans/human-5.png",
      videoUrl: "/humans/human-5.png",
      previewUrl: "/humans/human-5.png",
      imageIcoUrl: "/humans/human-5.png",
    },
  ];

  export const mockUp = {
    1: {
      id: 1,
      name: "Admin",
      generyName: "ASTRONAUT 1",
      imageUrl: "/humans/human-1.png",
      videoUrl: "/humans/human-1.png",
      previewUrl: "/humans/human-1.png",
      imageIcoUrl: "/humans/human-1.png",
    },
    2: {
      id: 2,
      name: "Academic",
      generyName: "ASTRONAUT 2",
      imageUrl: "/humans/human-2.png",
      videoUrl: "/humans/human-2.png",
      previewUrl:"/humans/human-2.png",
      imageIcoUrl: "/humans/human-2.png",
    },
    3: {
      id: 3,
      name: "Engineer",
      generyName: "ASTRONAUT 3",
      imageUrl: "/humans/human-3.png",
      videoUrl: "/humans/human-3.png",
      previewUrl: "/humans/human-3.png",
      imageIcoUrl: "/humans/human-3.png",
    },
    4: {
      id: 4,
      name: "Boss",
      generyName: "ASTRONAUT 4",
      imageUrl: "/humans/human-4.png",
      videoUrl:"/humans/human-4.png",
      previewUrl: "/humans/human-4.png",
      imageIcoUrl: "/humans/human-4.png",
    },
    5: {
      id: 5,
      name: "Robot",
      generyName: "ASTRONAUT 5",
      imageUrl:"/humans/human-5.png",
      videoUrl: "/humans/human-5.png",
      previewUrl: "/humans/human-5.png",
      imageIcoUrl: "/humans/human-4.png",
    },
  };

  export const peopleBase = [
    {
      id: level[4].id,
      name: "ASTRONAUT 5",
      level: level[4].name,
      exhausted: true,
      usage: 32,
      imageUrl:"/humans/human-5.png",
      videoUrl: "/humans/human-5.png",
      previewUrl: "/humans/human-5.png",
      exp: "320",
    },
    {
      id: level[3].id,
      name: "ASTRONAUT 4",
      level: level[3].name,
      usage: 32,
      imageUrl: "/humans/human-4.png",
      videoUrl:"/humans/human-4.png",
      previewUrl: "/humans/human-4.png",
      exp: "69",
    },
    {
      id: level[2].id,
      name: "ASTRONAUT 3",
      level: level[2].name,
      exhausted: true,
      usage: 22,
      imageUrl: "/humans/human-3.png",
      videoUrl: "/humans/human-3.png",
      previewUrl: "/humans/human-3.png",
      exp: "164",
    },
    {
      id: level[1].id,
      name: "ASTRONAUT 2",
      level: level[1].name,
      usage: 12,
      imageUrl: "/humans/human-2.png",
      videoUrl: "/humans/human-2.png",
      previewUrl: "/humans/human-2.png",
      exp: "40",
    },
    {
      id: level[0].id,
      name: "ASTRONAUT 1",
      level: level[0].name,
      exhausted: true,
      usage: 47,
      imageUrl: "/humans/human-1.png",
      videoUrl: "/humans/human-1.png",
      previewUrl: "/humans/human-1.png",
      exp: "220",
    },
  ];
  export const ship = [
    {
      id: 1,
      name: "1",
      seat: 2,
      class: "Basic",
      videoRocketUrl: "/rockets/rocket-1.jpg",
      previewUrl: "/rockets/rocket-1.jpg",
      imageUrl: RocketImage,
    },
    {
      id: 2,
      name: "2",
      seat: 4,
      class: "Economic",
      videoRocketUrl: "/rockets/rocket-2.jpg",
      previewUrl: "/rockets/rocket-2.jpg",
      imageUrl: RocketImage,
    },
    {
      id: 3,
      name: "3",
      seat: 6,
      class: "Executive",
      videoRocketUrl: "/rockets/rocket-3.jpg",
      previewUrl: "/rockets/rocket-3.jpg",
      imageUrl: RocketImage,
    },
    {
      id: 4,
      name: "4",
      seat: 9,
      class: "Boos",
      videoRocketUrl: "/rockets/rocket-4.jpg",
      previewUrl: "/rockets/rocket-4.jpg",
      imageUrl: RocketImage,
    },
    {
      id: 5,
      name: "5",
      seat: 8,
      class: "Premium",
      videoRocketUrl: "/rockets/rocket-5.jpg",
      previewUrl: "/rockets/rocket-5.jpg",
      imageUrl: RocketImage,
    },
    // More people...
  ];
  
  export 
  const ship_ = {
    1: {
      name: "1",
      seat: 5,
      class: "Basic",
      videoRocketUrl: "/rockets/rocket-1.jpg",
      previewUrl: "/rockets/rocket-1.jpg",
      imageUrl: "/rockets/rocket-1.jpg",
    },
    2: {
      name: "2",
      seat: 2,
      class: "Economic",
      videoRocketUrl: "/rockets/rocket-2.jpg",
      previewUrl: "/rockets/rocket-2.jpg",
      imageUrl: "/rockets/rocket-2.jpg",
    },
    3: {
      name: "3",
      seat: 2,
      class: "Executive",
      videoRocketUrl: "/rockets/rocket-3.jpg",
      previewUrl: "/rockets/rocket-3.jpg",
      imageUrl:  "/rockets/rocket-3.jpg",
    },
    4: {
      name: "4",
      seat: 2,
      class: "Boos",
      videoRocketUrl: "/rockets/rocket-4.jpg",
      previewUrl: "/rockets/rocket-4.jpg",
      imageUrl:  "/rockets/rocket-4.jpg",
    },
    5: {
      name: "5",
      seat: 2,
      class: "Premium",
      videoRocketUrl: "/rockets/rocket-5.jpg",
      previewUrl: "/rockets/rocket-5.jpg",
      imageUrl:"/rockets/rocket-5.jpg",
    },
    // More people...
  };