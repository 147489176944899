import React,{useEffect, useState} from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import InventoryScreen from "../views/Inventory/InventoryScreen";
import PlayScreen from "../views/Play/PlayScreen";
import BuyScreen from "../views/Buy/BuyScreen";
import MarketplaceScreenU from "../views/MarketplaceU/MarketplaceScreenU";
import "../css/game/marketplace.css";
import "../css/game/stplay.css";
import "../css/game/wactus.css";
import ModalScan from "../views/Play/ModalScan";
import ModalLose from "../views/Play/ModalLose";
import { NotificationMessage } from "../components/NotificationMessage/NotificationMessage";
import { Circle } from "../components/Circle";
import { motion } from "framer-motion";
import clsx from "clsx";
import { XIcon } from '@heroicons/react/outline'
import WalletScreen from "../views/Wallet/Wallet";
import WalletScreenU from "../views/WalletU/Wallet";

const DashboardRoutes = () => {
  const [status, setStatus] = React.useState(null);
  const [notification, setNotification] = React.useState(null);
  const [isStatus, setIsStatus] = React.useState(null);
  const [updateStatus, setUpdateStatus] = React.useState(0);
  const [first, setfirst] = useState(null);
  const [data, setdata] = useState(null);
  const playHandle = (tes) => {
    setfirst(true)
    setdata(tes)
    setUpdateStatus(updateStatus + 1);
    
  };

  useEffect(() => {   
    if(first==null)
    return
    
  console.log(data,"datadatadatadatadata");
  setStatus(data.win)
  const time = setTimeout(()=>setStatus(null),5000000 )
    return () => {
      clearTimeout(time)
      
    };
  }, [updateStatus]);
  
  return (
    <>
  
      <div className="bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#39004d] to-[#000]">
        <Navbar />
    
        <Switch>
          <Route exact path="/home">
            <PlayScreen
              status={status}
              setStatus={playHandle}
              notification={notification}
              setNotification={setNotification}
              isStatus={isStatus}
              setIsStatus={setIsStatus}
            />
          </Route>
          <Route exact path="/inventory">
            <InventoryScreen />
          </Route>
          <Route exact path="/buy">
            <BuyScreen />
          </Route>
    

          <Route exact path="/marketplaceUpdate">
            <MarketplaceScreenU />
          </Route>
          
          <Route exact path="/wallet">
            <WalletScreen />
          </Route>
          <Route exact path="/walletU">
            <WalletScreenU />
          </Route>


          
          <Redirect to="/home" />
        </Switch>
        
        {status !=null ?(status?<ModalScan data={isStatus} />:<ModalLose />):null}
        <NotificationMessage
          position="bottom-right"
          visible={notification === "successfully"}
        >
          <div className={clsx("flex items-center w-64")}>
            <motion.div
              className="absolute"
              transition={{
                repeat: Infinity,
                duration: 2,
                ease: "linear",
              }}
              animate={{ rotate: 360 }}
            >
              <Circle size={56} color="UNKNOWN" />
            </motion.div>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 1024 1024"
              className={clsx("inline w-6 ml-4")}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M864 736c0-111.6-65.4-208-160-252.9V317.3c0-15.1-5.3-29.7-15.1-41.2L536.5 95.4C530.1 87.8 521 84 512 84s-18.1 3.8-24.5 11.4L335.1 276.1a63.97 63.97 0 0 0-15.1 41.2v165.8C225.4 528 160 624.4 160 736h156.5c-2.3 7.2-3.5 15-3.5 23.8 0 22.1 7.6 43.7 21.4 60.8a97.2 97.2 0 0 0 43.1 30.6c23.1 54 75.6 88.8 134.5 88.8 29.1 0 57.3-8.6 81.4-24.8 23.6-15.8 41.9-37.9 53-64a97 97 0 0 0 43.1-30.5 97.52 97.52 0 0 0 21.4-60.8c0-8.4-1.1-16.4-3.1-23.8L864 736zM512 352a48.01 48.01 0 0 1 0 96 48.01 48.01 0 0 1 0-96zm116.1 432.2c-5.2 3-11.2 4.2-17.1 3.4l-19.5-2.4-2.8 19.4c-5.4 37.9-38.4 66.5-76.7 66.5s-71.3-28.6-76.7-66.5l-2.8-19.5-19.5 2.5a27.7 27.7 0 0 1-17.1-3.5c-8.7-5-14.1-14.3-14.1-24.4 0-10.6 5.9-19.4 14.6-23.8h231.3c8.8 4.5 14.6 13.3 14.6 23.8-.1 10.2-5.5 19.6-14.2 24.5z"></path>
            </svg>

            <div className="inline-grid ml-10">
              <div className="flex col-start-1 row-start-1 overflow-hidden text-gray-700">
                Please wait. Loading...
              </div>
            </div>
          </div>
        </NotificationMessage>
      </div>
    </>
  );
};
export default DashboardRoutes;
