import { Fragment, useRef, useState,useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import InventoryContext from "../../context/Inventory";
import MarketplaceContext from "../../context/MarketplaceContextU";
import { InformationCircleIcon } from '@heroicons/react/solid'
import { UseGame, UseHuman, useMarketplaceU as useMarketplace } from "../../hooks/useContracts";
import { ethers, utils } from "ethers";
import { useToasts } from "react-toast-notifications";
export default function ModalSell({ sell, setSell,tokenID,isDead,id}) {
  const { people, generalData, updateHandle } = useContext(InventoryContext);
  const { isApproveNft, ApproveHandleNft } = useContext(MarketplaceContext);


  
  const Marketplace =  useMarketplace()
  const [open, setOpen] = useState(false);
  
  const cancelButtonRef = useRef(null);
  const [amount, setamount] = useState(0);
  const { addToast } = useToasts();



  const readyToSellToken = async () => {   
    try {      
      const [load, contract] = await Marketplace;
      const price = ethers.utils.parseEther(amount.toString()) 
      if(amount==0)
      return addToast("error", { appearance: "warning" });            
      const res = await contract.readyToSellToken(id,price,{value:utils.parseEther("0.0011")});       
       setOpen(false);
       addToast("success", { appearance: "success" });
      res.wait().then(() => {
        addToast("readyToSellToken success", { appearance: "success" });
        //setStatus("Complete");        
        updateHandle();
      });
    } catch (error) {
      
      console.log(error);
      if(error.data !== undefined) {
        addToast(error.data.message, { appearance: 'error' });
      } else {
        addToast(error.message, { appearance: 'error' });
      }
    }
  };

  return (
    <>
      {!isDead ?
      <button
        onClick={() => setOpen(true)}
        type="button"
        className="border border-gray-300 bg-indigo-600 hover:bg-white text-sm font-medium text-white hover:text-indigo-800 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
      </button>:null}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
          style={{ zIndex: "99999999" }}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-[#14181d] rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="">
                  <div className="">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-700 text-center"
                    >
                      Sell
                    </Dialog.Title>
                    <div className="mt-2 flex justify-center">
                      <div className="">
                        <label htmlFor="sell" className="sr-only">
                          Sell
                        </label>
                        <input                        
                          type="number"
                          name="sell"
                          id="sell"
                          min={0}
                          className="bg-[#2f3740] shadow-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Price"
                          onChange={(e) =>{
                            if(e.target.value=="")
                          return
                          setamount(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="rounded-md pt-2 bg-blue-50 mt-4">
      <div className="flex mx-4">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">Remember that you will be charged 15% of your sales in the marketplace.</p>
        </div>
      </div>
    </div>

               </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex justify-center">
                  {isApproveNft?<button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#437bc16e] text-base font-medium text-[#34a1ff]    sm:w-auto sm:text-sm"
                    onClick={() => {
                      readyToSellToken()  
                      setOpen(false)
                    
                    }}
                  >
                    Start Auctions
                  </button>:
                  <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#437bc16e] text-base font-medium text-[#34a1ff]   sm:w-auto sm:text-sm"
                  onClick={() => {
                    ApproveHandleNft()
                    //setOpen(false)
                  
                  }}
                >
                  Approvenft
                </button>}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
