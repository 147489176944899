/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState, useEffect, useContext } from "react";
import Checkbox from "../../components/Checkbox";
import clsx from "clsx";
import ImgS3 from "../../components/ImgS3";
import ImgS3Ximbia from "../../components/ImgS3Ximbia";




const PeopleHandle=({people,handleClick,state})=>{
  if (people.dead) return null;  
  return (
    <dt      
      className={[
        "py-3 flex justify-between items-center",
        people.exhausted ? "bg-red-500" : "",
      ]}
    >
      <div className="flex items-center">
        <ImgS3Ximbia
          src={people.imageIcoUrl}
          alt={people.imageIcoUrl}
          className="w-10 h-10 rounded-md"
        />
        <div>
          <p
            className={`${
              people.exhausted
                ? "text-red-300 ml-4 text-sm font-bold"
                : "ml-4 text-sm font-medium text-gray-700 font-bold"
            }`}
          >
            {people.name}{" "}
            <span
              className={clsx("", {
                "px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800":
                people.exhausted,
              })}
            >
              {people.exhausted ? "exhausted" : ""}
            </span>
          </p>

          <p className="ml-4 -mt-3 text-sm font-medium text-gray-700 font-normal">
            Level: {people.level}
          </p>
        </div>
      </div>
      <div className="mr-4">
        <Checkbox
          key={people.id}
          type="checkbox"
          handleClick={(e) =>
            handleClick(e, 
              people.tokenID,
              0,
              people.id,
              people.exhausted)
          }
          isChecked={state.has(people.tokenID)}
        />
      </div>
    </dt>);
}

export const PeopleList=({handleClick,peoples,state})=>{
  const [list,setList]= useState([]);
  useEffect(() => {    
    setList(peoples)
    return () => {
      
    };
  }, [peoples]);
  //({ id, name, level, tokenID, exp, dead, exhausted, imageIcoUrl }, i) => { 
  return(
    <>
    {list.length>0?list.map(  
      ( people, i) => <PeopleHandle key={i} people={people} handleClick={handleClick} state={state} />      
    ):null
  }
  </>)  
}