import { useMemo,useContext,useState} from 'react';
import {IBEP20,Clone, ERC721,MArket,Auction,Human,Marketplace,MarketplaceU,Game,Fuse,ORACLE,BuyHumanFactory}  from './abiHelpers'
import Web3Context from '../context/Web3Context'
import {Contract,ethers} from 'ethers'

export const address ={  
  token: process.env.REACT_APP_TOKEN,  
  humanFactory: process.env.REACT_APP_HUMAN_FACTORY,
  game: process.env.REACT_APP_GAME,//proxy 
  fuse: process.env.REACT_APP_FUSE ,  
  Marketplace: process.env.REACT_APP_MARKETPLACE,
  MarketplaceU: process.env.REACT_APP_MARKETPLACEU,
  market:process.env.REACT_APP_MARKETPLACE,
  oracle: process.env.REACT_APP_ORACLE,
  buyHumanFactory:process.env.REACT_APP_BUY_HUMAN_FACTORY
}

export const useBuyHumanFactory = ()=>{    
  const {accounts, isLoaded} = useContext(Web3Context)        
  return useMemo(async() => {
    if(!isLoaded)
    return [false,null]
    const provider =await etherJSProvider()
    const signer = provider.getSigner()    
    const contract = new Contract(address.buyHumanFactory,BuyHumanFactory,signer)          
    return [true, contract]
    },[accounts,isLoaded])
}


export const useMarketplace = ()=>{    
  const {accounts, isLoaded} = useContext(Web3Context)        
  return useMemo(async() => {
    if(!isLoaded)
    return [false,null]
    const provider =await etherJSProvider()
    const signer = provider.getSigner()    
    const contract = new Contract(address.Marketplace,Marketplace,signer)          
    return [true, contract]
    },[accounts,isLoaded])
}

export const useMarketplaceU = ()=>{    
  const {accounts, isLoaded} = useContext(Web3Context)        
  return useMemo(async() => {
    if(!isLoaded)
    return [false,null]
    const provider =await etherJSProvider()
    const signer = provider.getSigner()    
    const contract = new Contract(address.MarketplaceU,MarketplaceU,signer)          
    return [true, contract]
    },[accounts,isLoaded])
}

const etherJSProvider =async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    return provider;  
  }


  export const useToken = ()=>{    
    const {accounts, isLoaded} = useContext(Web3Context)        
    return useMemo(async() => {
      if(!isLoaded)
      return [false,null]
      const provider =await etherJSProvider()
      const signer = provider.getSigner()    
      const contract = new Contract(address.token,IBEP20,signer)          
      return [true, contract]
      },[accounts,isLoaded])
  }
 
  export const UseMArket = ()=>{    
    const {accounts, isLoaded} = useContext(Web3Context)        
    return useMemo(async() => {
      if(!isLoaded )
      return [false,null]
      const provider =await etherJSProvider()
      const signer = provider.getSigner()      
      const contract = new Contract(address.market,MArket,signer)    
      
      return [true, contract]
      },[accounts,isLoaded])
  }

  export const UseGame = (address_)=>{    
    const {accounts, isLoaded} = useContext(Web3Context)        
    return useMemo(async() => {
      if(!isLoaded )
      return [false,null]
      const provider =await etherJSProvider()
      const signer = provider.getSigner()      
      const contract = new Contract(address.game,Game,signer)    
      
      return [true, contract]
      },[accounts,isLoaded])
  }

  export const UseHuman = (address_)=>{    
    const {accounts, isLoaded} = useContext(Web3Context)        
    return useMemo(async() => {
      if(!isLoaded )
      return [false,null]
      const provider =await etherJSProvider()
      const signer = provider.getSigner()      
      const contract = new Contract(address.humanFactory, Human,signer)    
      
      return [true, contract]
      },[accounts,isLoaded])
  }

  export const UseFuse = ()=>{    
    const {accounts, isLoaded} = useContext(Web3Context)        
    return useMemo(async() => {
      if(!isLoaded )
      return [false,null]
      const provider =await etherJSProvider()
      const signer = provider.getSigner()      
      const contract = new Contract(address.fuse, Fuse,signer)    
      
      return [true, contract]
      },[accounts,isLoaded])
  }

  export const UseOracle = ()=>{    
    const {accounts, isLoaded} = useContext(Web3Context)        
    return useMemo(async() => {
      if(!isLoaded )
      return [false,null]
      const provider =await etherJSProvider()
      const signer = provider.getSigner()      
      const contract = new Contract(address.oracle, ORACLE,signer)    
      
      return [true, contract]
      },[accounts,isLoaded])
  }



