import React, { useState, useEffect, useRef, useContext } from "react";
import { level } from "../../context/data";
import ReactPaginate from "react-paginate";
import { ethers, utils } from "ethers";
import MarketplaceContext from "../../context/MarketplaceContextU";
import clsx from "clsx";
import ImgS3 from "../../components/ImgS3";
const HumanMarket = [
  {
    id: 1,
    name: "ASTRONAUT 1",
    href: "#",
    box: 3500,
    imageSrc: "/images/marketplace/human/level1.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    exp: 0,
    post: 0,
    level: "Admin",
    remainingFee: 0
  },
  {
    id: 2,
    name: "ASTRONAUT 2",
    href: "#",
    box: 3500,
    imageSrc: "/images/marketplace/human/level2.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    exp: 0,
    post: 0,
    level: "Academic",
    remainingFee: 0
  },
  {
    id: 3,
    name: "ASTRONAUT 3",
    href: "#",
    box: 3000,
    imageSrc: "/images/marketplace/human/level3.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    exp: 0,
    post: 0,
    level: "Engineer",
    remainingFee: 0
  },
  {
    id: 4,
    name: "ASTRONAUT 4",
    href: "#",
    box: 2600,
    imageSrc: "/images/marketplace/human/level4.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    exp: 0,
    post: 0,
    level: "Boss",
    remainingFee: 0
  },
  {
    id: 5,
    name: "ASTRONAUT 5",
    href: "#",
    box: 0,
    imageSrc: "/images/marketplace/human/level5.png",
    imageAlt: "Front of men's Basic Tee in black.",
    price: 0,
    exp: 0,
    post: 0,
    level: "Robot",
    remainingFee: 0
  },
];

function HumanW({ object }) {
  const [searchLevel, setSearchLevel] = useState("");
  const [searchPrices, setSearchPrices] = useState("");
  const [searchExp, setSearchExp] = useState("");
  const [searchTime, setSearchTime] = useState("");
  const [page, setPage] = useState(0);
  const [employeesPerPage, setemployeesPerPage] = useState(33);
  const [numberOfEmployeesVistited, setnumberOfEmployeesVistited] = useState(
    page * employeesPerPage
  );
  const [loading, setLoading] = useState(true);
  const [Human, setHuman] = useState([]);

  useEffect(() => {
    if (object.length == 0) {
      return;
    }
    let obj = object.map((value) => {
      const data_ = HumanMarket[value.level - 1];
      {
        // id: 1,
        // name: "ASTRONAUT 1",
        // href: "#",
        // box: 3500,
        // imageSrc: "/images/marketplace/human/level1.png",
        // imageAlt: "Front of men's Basic Tee in black.",
        // price: 16,
        // exp: 20,
        // post: 200,
        // level: "Admin",
      }
      return Object.assign(
        {
          name: data_.name,
          imageSrc: data_.imageSrc,
          imageAlt: data_.imageAlt,
          level: data_.level,
        },
        value
      );
    });

    setHuman(obj);

    return () => {};
  }, [object]);

  useEffect(() => {
    const time = setTimeout(() => {
      setLoading(false);
    }, 10000);

    return () => {
      clearTimeout(time);
    };
  }, []);
  const levelCheck = [
    {
      level: "Admin",
      id: 1,
    },
    {
      level: "Academic",
      id: 2,
    },
    {
      level: "Engineer",
      id: 3,
    },
    {
      level: "Boss",
      id: 4,
    },
    {
      level: "Robot",
      id: 5,
    },
  ];

  function refreshPage() {
    window.location.reload();
  }

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  useEffect(() => {
    setnumberOfEmployeesVistited(page * employeesPerPage);
  }, [page]);

  const totalPages = Math.ceil(Human.length / employeesPerPage);

  return (
    <div className="relative ">
      <form className="mx-auto mx-8 " style={{ maxWidth: "110rem" }}>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5 col-span-full">
          {/* Payment details */}
          <div className="space-y-6 sm:px-6 lg:px-0 col-span-full">
            <section>
              {loading && Human.length == 0 ? (
                <div class="flex justify-center items-center mt-20">
                  <div class="animate-spin rounded-full h-36 w-36 border-t-2 border-b-2 border-white"></div>
                </div>
              ) : null}
              {!loading && Human.length == 0 ? (
                <>
                  <div class="flex justify-center items-center mt-20">
                    <div>
                      <h1>DATA NO FOUND</h1>
                    </div>
                  </div>

                  <div class="flex justify-center items-center mt-20">
                    <button
                      onClick={refreshPage}
                      type="button"
                      className=" px-2.5 py-1.5 border border-transparent text-xl font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Reload
                    </button>
                  </div>
                </>
              ) : null}
              <div className="mt-6 grid grid-cols-1 gap-y-4 sm:grid-cols-1 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-3 mx-auto max-w-5xl ">
                {Human.length > 0
                  ? Human.slice(
                      numberOfEmployeesVistited,
                      numberOfEmployeesVistited + employeesPerPage
                    )
                      .filter((employeesPerPage) => {
                        if (searchLevel === "") {
                          return employeesPerPage;
                        } else if (
                          employeesPerPage.level
                            .toLowerCase()
                            .includes(searchLevel.toLowerCase())
                        ) {
                          return employeesPerPage;
                        }
                      })
                      .sort((a, b) => {
                        if (searchTime === "oldest") {
                          return b.post - a.post;
                        }
                        return a, b;
                      })
                      .sort((a, b) => {
                        if (searchExp === "min") {
                          return a.exp - b.exp;
                        }
                        return a, b;
                      })
                      .sort((a, b) => {
                        if (searchExp === "max") {
                          return b.exp - a.exp;
                        }
                        return a, b;
                      })
                      .sort((a, b) => {
                        if (searchPrices === "higher") {
                          return b.price - a.price;
                        }
                        return a, b;
                      })
                      .map((human, id) => <HumanItem key={id} human={human} />)
                  : null}
              </div>
              <div className="mt-6">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={totalPages}
                  onPageChange={changePage}
                  containerClassName={"navigationButtons"}
                  previousLinkClassName={"previousButton"}
                  nextLinkClassName={"nextButton"}
                  disabledClassName={"navigationDisabled"}
                  activeClassName={"navigationActive"}
                />
              </div>
            </section>
          </div>
        </div>
      </form>
    </div>
  );
}

export default HumanW;

function HumanItem({ human }) {
  const {
    isApproveNft,
    isApprove,
    buy,
    setgameupdate,
    gameApproveHandle,
    ApproveHandleNft,
    cancelSellToken,
    askList,
    myListing,
  } = useContext(MarketplaceContext);

  return (
    <div estin_ text-vd7 className="relative__hb_hu flex justify-center">
    <div
      className={clsx("card crd ", {
        "admin ": human.level === "1",
        academic: human.level === "2",
        engineer: human.level === "3",
        "boss ": human.level === "4",
        robot: human.level === "5",
      })}
      style={{ height: "441px" }}
    >
      <div className="nft-title">
        <h3> {human.name}</h3>
      </div>
      <div estin_ className="card-fee-data">
        <div estin_>
        
          {/*
          <p estin_ className="fdes text-center">
            <span estin_ className="is-text-regular is-color-lightGrey">
              Usage:
            </span>{" "}
            <span estin_ className="is-text-regular ranft">
              {usage} days
            </span>
          </p>
          
          */}
          
        </div>
      </div>
      <div className="card-body">
        <div className="crd__img-container">
          <ImgS3
            src={human.imageSrc}
            alt={human.imageAlt}
            loading="lazy"
            className="crd-mainimg"
          />
        </div>
        <div className="crd-tags_nft clearfix ml-2">
          <span className="crd-tag_nft">Level: {human.level}</span>
          <span className="crd-tag_nft" style={{ marginLeft: "8px" }}>
            EXP: {human.exp}
          </span>
        </div>
      </div>
      <div className="data_hw">
        <div className="flex-ston ds_1">
          <div className="flex-crit">
            <div className="mt-2">
              <div>{utils.formatEther(human.price)} SSAP</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-2">
      {isApprove && isApproveNft ? (
          <div className="flex">

            <button
                 onClick={() => cancelSellToken(human.tokenId)}
                class="actb approve w-20"
                style={{ width: "179px" }}
              >
                <span
                  style={{
                    fontFamily: "Titles, sans-serif",
                    letterSpacing: "0.5pt",
                  }}
                >
                   CANCEL SALE
                </span>
              </button>
          </div>
        ) : (
          <div className="flex">
            {!isApprove && (
             <button
             onClick={() => gameApproveHandle()}
             class="actb approve w-20"
             style={{ width: "179px" }}
           >
             <i class="fas fa-scroll"></i>
             <span
               style={{
                 fontFamily: "Titles, sans-serif",
                 letterSpacing: "0.5pt",
               }}
             >
               Approve Token
             </span>
           </button>
            )}

            {!isApproveNft && (
            <button
            onClick={() => ApproveHandleNft()}
            class="actb approve"
            style={{ width: "171px" }}
          >
            <i class="fas fa-scroll"></i>
            <span
              style={{
                fontFamily: "Titles, sans-serif",
                letterSpacing: "0.5pt",
              }}
            >
              Approve NFT
            </span>
          </button>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
  );
}
