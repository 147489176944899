import React, { useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHotkeys } from "react-hotkeys-hook";
import { useClickAway } from "react-use";
import InventoryContext from "../../context/Inventory";
import { UseGame, UseHuman } from "../../hooks/useContracts";
import { useToasts } from "react-toast-notifications";
import ImgS3 from "../../components/ImgS3";
import ImgS3Ximbia from "../../components/ImgS3Ximbia";

const rocketF_ = [
  // More people...
];
export default function ModalFeesAllRocket({
  gift,
  transfer,
  id,
  gameObjets,
  selected,
  resetCheckedBoxHandle,
}) {
  const { people, generalData, updateHandle } = useContext(InventoryContext);
  const [open, setOpen] = React.useState(false);
  const [scan, setScan] = React.useState(true);
  const [rocketF, setRocketF] = useState(rocketF_);
  const [feePay, setfeePay] = useState(0);
  const [humanF, sethumanF] = useState(rocketF_);
  const [status, setStatus] = useState(null);
  const { addToast } = useToasts();
  const Game = UseGame();
  const humanPayFeesArrayUSD = [6, 8, 12, 20, 0];


  /* forms */
  /* forms-end */
  const ref = React.useRef(null);
  const boxRef = React.useRef(null);
  useHotkeys("cmd+k", () => setOpen(true));
  useHotkeys("ctrl+k", () => setOpen(true));
  useHotkeys("esc", () => setOpen(false));
  useClickAway(boxRef, () => setOpen(false));

  useEffect(() => {
    const selet = Array.from(selected);
    let payAmount = 0;
    let humanAlive;
    if (id == 1)
      humanAlive = gameObjets
        .map((value) => {
          let fee;
          if (id == 1) fee = humanPayFeesArrayUSD[value.level - 1];
          else fee = humanPayFeesArrayUSD[Number(value.id) - 1];
          if (value.exhausted == true) payAmount += fee;
          return Object.assign(value, { fee });
        })
        .filter((value) => {
          return value.exhausted == true;
        });
    
    if (payAmount == 0 && false) {
      /**
           addToast("all fee pay", { appearance: "success" });
       */
  
      setOpen(false);
      return;
    }
    setfeePay(payAmount);
    console.log(payAmount);
    sethumanF(humanAlive);
    setRocketF(humanAlive);

    return () => {};
  }, [open]);

  const playFee = async () => {
    try {
      if (humanF.length == 0) throw "NO fee";
      const [load, contract] = await Game;
      const selet = Array.from(selected);
      
      
      
      const res = await contract.playAllNftByType(1);

      setStatus("successfully");      
      setOpen(false);
      res.wait().then(() => {
        addToast("feePay success", { appearance: "success" });
        setStatus("Complete");
        resetCheckedBoxHandle();
        updateHandle();
      });
    } catch (error) {
      // if(error.data.message !== undefined) {
      //   addToast(error.data.message, { appearance: 'error' });
      // } else {
        console.log(error);
        addToast(error, { appearance: 'error' });
      // }
    }
  };
  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="title ml-3 mb-3 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-purple-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Fee's
      </button>
      <Transition.Root as={React.Fragment} show={open}>
        <Dialog
          as="div"
          className="overflow-y-auto fixed inset-0 z-50"
          initialFocus={ref}
          onClose={setOpen}
          open={open}
          static
          style={{ zIndex: "99999999" }}
        >
          <div className="flex justify-center items-center px-4 pt-4 pb-20 min-h-screen text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-[#181c20] bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              aria-hidden="true"
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-purple-grandient border border-indigo-500  rounded-lg px-0 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl w-full sm:p-24 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="transition-all transform">
                  <div className="" ref={boxRef}>

                  <div className="box-body mx-xl-18 pt-0">
                      <div className="text-center">
                        <h1 className="mb-3 flex justify-center text-white">
                          Total Ship NFT fee
                        </h1>
                        <div className="text-muted fw-bold fs-5">
                          This action is irreversible, take precautions.
                        </div>
                      </div>
                      <div className="mb-15">
                        <div className="mh-375 scroll-y me-n7 pe-7">
                          {rocketF.map((rocket, index) => {
                            return (
                              <div className="d-flex flex-stack py-5 border-bottom border-gray-300">
                                <div className="d-flex align-items-center">
                                  <div className="cad w-16 h-20 cad-circle">
                                    <ImgS3Ximbia src={rocket.imageUrl} />
                                  </div>
                                  <div className="ms-6">
                                    <a className="d-flex align-items-center fs-5 fw-bolder txt-item_">
                                      Rocket {rocket.id}
                                    </a>
                                    <div className="fw-bold text-muted">
                                      {" "}
                                      Class: {rocket.class}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="text-end">
                                    <div className="fs-5 fw-bolder txt-item_">
                                      $ {rocket.fee}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className="row mb-4 flex-center"
                        style={{ alignContent: "center" }}
                      >
                        <div className="fs-5 fw-bold mt-2 mb-3 text-white">
                          Total Price:
                        </div>
                        <button className="bg-slate-200 py-2 rounded-lg font-bold text-xl me-3">
                        {30 + feePay} $ XMB
                        </button>
                      </div>
                      <div className="box-footer flex-center">
                        {rocketF.length !== 0 && (
                          <button
                            onClick={(e) => playFee()}
                            className="btn btn-primary"
                          >
                            <span>Pay now!</span>
                          </button>
                        )}
                      </div>
                    </div>

                    
   
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
