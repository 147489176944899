import { Fragment, useState,useContext,useEffect } from "react";
import { Tab } from "@headlessui/react";
import HumanM from "./HumanM";
import RocketM from "./RocketM";
import { UseGame, UseHuman, useMarketplace } from "../../hooks/useContracts";
import { ethers } from "ethers";
import { useToasts } from "react-toast-notifications";
import MarketplaceContext from "../../context/MarketplaceContextU";
import ModalInfo from "./ModalInfo";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MarketplaceScreenU() {
  const { isApproveNft,
    isApprove,
    setgameupdate ,
    gameApproveHandle,
    askList} = useContext(MarketplaceContext);
    const [humanList, sethumanList] = useState([]);
    const [rocketList, setrocketList] = useState([]);

  
    useEffect(() => {
      if(askList.length === 0)
      return
      const a = askList.filter(value=>value.seat>0)
      const b = askList.filter(value=>value.seat==0)

      
      sethumanList(b)
        setrocketList(a)
      return () => {
      
      };
    }, [askList]);
    
  
  
  
    const categories = {
    Human: [
      {
        id: 1,
        component: <HumanM object={humanList}/>,
      },
    ],
    Rocket: [
      {
        id: 1,
        component: <RocketM object={rocketList}/>,
      },
    ],
  };



  return (
    <>
    {
      /*
          <ModalInfo />
       */
    }

    <div id="hakkv" className="">
      <div className="inventory-content">
        <div vphawk className="relative__hb hw-main">
          <div vphawk className="inventory_nft">
            <div vphawk className="relative__hb">
              <div vphawk className="inventory-assets">
                <div className="head_ivt">
                  <div className="mt-28">
                    <Tab.Group>
                      <Tab.List className="flex justify-center">
                        {Object.keys(categories).map((category) => (
                          <Tab
                            key={category}
                            className={({ selected }) =>
                              classNames(
                                "tab",
                                selected
                                  ? "bg-[#46567a]  text-white"
                                  : "bg-[#252c38] text-gray-400"
                              )
                            }
                          >
                            {category}
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels className="">
                       
                       
                      
                        {
                        
                        Object.values(categories).map((posts, idx) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Tab.Panel key={idx}>
                            {posts.map((post, i) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <div className="" key={i}>
                                {post.component}
                              </div>
                            ))}
                          </Tab.Panel>
                        ))
                        }
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

        </>
  );
}
export default MarketplaceScreenU;

