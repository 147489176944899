import { Fragment, useRef, useState, useEffect, useContext } from "react";
// import "../../css/game/wactus.css";
// import "../../css/game/stplay.css";
// import { PlanetData } from "../../../data/PlanetData";
// import InventoryContext from "../../../context/Inventory";
// import SlidePlay from "../SlidePlay";
// import ImgS3 from "../../../components/ImgS3";
import { UseGame } from "../../../hooks/useContracts";
import { useToasts } from "react-toast-notifications";
//import { useCountdown } from "../../../hooks/useCountdown";
import { DateTime } from "luxon";
import { Popover, Transition } from "@headlessui/react";

export const Win = ({ generalData }) => {
  return (
    <div className="p-5 bg-primary border border-purple-700 rounded-lg">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 576 512"
            className="h-6 w-6 text-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M552 64H448V24c0-13.3-10.7-24-24-24H152c-13.3 0-24 10.7-24 24v40H24C10.7 64 0 74.7 0 88v56c0 35.7 22.5 72.4 61.9 100.7 31.5 22.7 69.8 37.1 110 41.7C203.3 338.5 240 360 240 360v72h-48c-35.3 0-64 20.7-64 56v12c0 6.6 5.4 12 12 12h296c6.6 0 12-5.4 12-12v-12c0-35.3-28.7-56-64-56h-48v-72s36.7-21.5 68.1-73.6c40.3-4.6 78.6-19 110-41.7 39.3-28.3 61.9-65 61.9-100.7V88c0-13.3-10.7-24-24-24zM99.3 192.8C74.9 175.2 64 155.6 64 144v-16h64.2c1 32.6 5.8 61.2 12.8 86.2-15.1-5.2-29.2-12.4-41.7-21.4zM512 144c0 16.1-17.7 36.1-35.3 48.8-12.5 9-26.7 16.2-41.8 21.4 7-25 11.8-53.6 12.8-86.2H512v16z"></path>
          </svg>
        </div>
        <div className="ml-5 w-20 flex-1">
          <dl>
            <dt className="text-sm font-medium text-white truncate">Wins</dt>
            <dd>
              <div className="text-lg font-medium text-white">
                {generalData.wins}
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export const Loser = ({ generalData }) => {
  return (
    <div className="bg-primary border border-purple-700 overflow-hidden shadow rounded-lg ">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              className="h-6 w-6 text-red-500"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"></path>
            </svg>
          </div>
          <div className="ml-5 w-20 flex-1">
            <dl>
              <dt className="text-sm font-medium text-white truncate">
                Lose
              </dt>
              <dd>
                <div className="text-lg font-medium text-white">
                  {generalData.loser}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Withdraw = ({
  pay,
  menuTitle = "Fee's",
  generalData,
  linksArray = [
    [`You have 30% fee. To have 0% fee, there are 15/0 days left. `],
  ],
}) => {
  let timeout;
  const timeoutDuration = 500;

  const Game = UseGame();
  const { addToast } = useToasts();
  const [canWithdraw, setcanWithdraw] = useState(false);
  const Earn = async (e) => {
    // console.log("canWithdraw");
    
    if (canWithdraw) addToast("WithDraw fail", { appearance: "info" });
    try {
      const [load, contract] = await Game;
      const res = await contract.withDraw();
      addToast("WithDraw success");
      res.wait().then((value) => {
        addToast("WithDraw ready");
      });
    } catch (e) {
      addToast("WithDraw fail");
      // addToast(e.data.message);
    }
  };
  useEffect(() => {
    const nowDate = DateTime.now();
    const lastWindra = DateTime.fromSeconds(Number(generalData.lastPlay));
    const nextWindra = lastWindra.plus({ minute: 5 });
    setcanWithdraw(nowDate > nextWindra);
    return () => {};
  }, [generalData.lastPlay]);

  const buttonRef = useRef(null);
  const [openState, setOpenState] = useState(false);

  const toggleMenu = (open) => {
    setOpenState((openState) => !openState);

    buttonRef?.current?.click(); // eslint-disable-line
  };

  const onHover = (open, action) => {
    if (
      (!open && !openState && action === "onMouseEnter") ||
      (open && openState && action === "onMouseLeave")
    ) {
      clearTimeout(timeout);

      timeout = setTimeout(() => toggleMenu(open), timeoutDuration);
    }
  };

  const handleClick = (open) => {
    console.log(pay);
    return;
    setOpenState(!open);
    clearTimeout(timeout);
  };

  const LINK_STYLES = classNames("text-white  items-center justify-center");
  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      event.stopPropagation();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <a href={process.env.REACT_APP_AUDIT_URL} target="_blank" rel="noreferrer">
      <div
        className={classNames(
          "You have 30% fee. To have 0% fee, there are 15/0 days left."
        )}
      >
        <Popover className="" style={{ zIndex: 999999999 }}>
          {({ open }) => (
            <div
              onMouseEnter={() => onHover(open, "onMouseEnter")}
              onMouseLeave={() => onHover(open, "onMouseLeave")}
              className=""
            >
              <Popover.Button ref={buttonRef} >
                <div
                  className={classNames(
                    open
                      ? "bg-gray-200 overflow-hidden shadow rounded-lg  hover:ring-2 hover:ring-offset-2 "
                      : " bg-gray-200 rounded-lg",

                    "flex justify-center text-white",
                    LINK_STYLES
                  )}
                  onClick={() => handleClick(open)}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          className="w-6 h-6 text-green-400"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M215.469 332.802l29.863 29.864L352 256 245.332 149.333l-29.863 29.865 55.469 55.469H64v42.666h205.864l-54.395 55.469zM405.334 64H106.666C83.198 64 64 83.198 64 106.666V192h42.666v-85.333h298.668v298.668H106.666V320H64v85.334C64 428.802 83.198 448 106.666 448h298.668C428.802 448 448 428.802 448 405.334V106.666C448 83.198 428.802 64 405.334 64z"></path>
                        </svg>
                      </div>
                      <div className="ml-5 w-32 flex-1" onClick={() => Earn()}>
                        <dl>
                          <dt className="text-sm font-medium text-white truncate">
                            Withdraw
                          </dt>
                          <dd>
                            <div className="text-lg font-medium text-gray-700">
                              {generalData.reward}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="z-10 absolute lg:right-[230px] right-[130px] top-48"
                  style={{ zIndex: 999999999 }}
                >
                  <div
                    className={classNames(
                      "flex self-start px-6 py-1 text-black text-md  bg-[#000833] rounded-lg -mt-28 ml-2"
                    )}
                  >
                    {linksArray.map(([title, href]) => (
                      <Fragment key={"PopoverPanel<>" + title + href}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-green-400 mt-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <p href={href} className="text-shadow -mb-0 ml-2 leading-2">
                        You have {generalData.tax}% tax. In order to have 0% you
                        have to wait {generalData.daysToZeroTax} days
                        </p>
                        <p>
                          
                        </p>
                        
                      </Fragment>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </div>
          )}
        </Popover>
      </div>
    </a>
  );
};

export const Timer = ({ generalData }) => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const [CANPLAY, setCanplay] = useState(true);

  let interval;
  const startTimer = (date) => {
    const target = date;
    interval = setInterval(() => {
      const distance = target.diffNow() > 0 ? target.diffNow() : 0;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance < 0) {
        clearInterval(interval);
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer(DateTime.now().plus({ days: 1 }));
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const nowDate = DateTime.now();
    const lastWindra = DateTime.fromSeconds(Number(generalData.lastPlay));
    const nextWindra = lastWindra.plus({ days: 24 });
    clearInterval(interval);
    if (!generalData.canplay) {
      startTimer(nextWindra);
      setCanplay(false);
    } else {
      setCanplay(true);
    }
    return () => {
      clearInterval(interval);
    };
  }, [generalData.lastPlay]);

  return (
    <div className="bg-primary border border-purple-700 overflow-hidden shadow rounded-lg ">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              t="1569683618210"
              viewBox="0 0 1024 1024"
              version="1.1"
              className="w-6 h-6 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <path d="M945 412H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h256c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM811 548H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h122c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM477.3 322.5H434c-6.2 0-11.2 5-11.2 11.2v248c0 3.6 1.7 6.9 4.6 9l148.9 108.6c5 3.6 12 2.6 15.6-2.4l25.7-35.1v-0.1c3.6-5 2.5-12-2.5-15.6l-126.7-91.6V333.7c0.1-6.2-5-11.2-11.1-11.2z"></path>
              <path d="M804.8 673.9H747c-5.6 0-10.9 2.9-13.9 7.7-12.7 20.1-27.5 38.7-44.5 55.7-29.3 29.3-63.4 52.3-101.3 68.3-39.3 16.6-81 25-124 25-43.1 0-84.8-8.4-124-25-37.9-16-72-39-101.3-68.3s-52.3-63.4-68.3-101.3c-16.6-39.2-25-80.9-25-124 0-43.1 8.4-84.7 25-124 16-37.9 39-72 68.3-101.3 29.3-29.3 63.4-52.3 101.3-68.3 39.2-16.6 81-25 124-25 43.1 0 84.8 8.4 124 25 37.9 16 72 39 101.3 68.3 17 17 31.8 35.6 44.5 55.7 3 4.8 8.3 7.7 13.9 7.7h57.8c6.9 0 11.3-7.2 8.2-13.3-65.2-129.7-197.4-214-345-215.7-216.1-2.7-395.6 174.2-396 390.1C71.6 727.5 246.9 903 463.2 903c149.5 0 283.9-84.6 349.8-215.8 3.1-6.1-1.4-13.3-8.2-13.3z"></path>
            </svg>
          </div>
          <div className="ml-5 w-32 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-700 ">
                Time left to play
              </dt>
              <dd>
                <div className="text-lg font-medium text-white">
                  {CANPLAY ? 0 : timerHours} hrs, {CANPLAY ? 0 : timerMinutes} Min
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

const StabHandle = ({ generalData }) => {
  return (
    <div className="mt-2 flex">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        <Win generalData={generalData} />
        <Loser generalData={generalData} />
        <Timer generalData={generalData} />
                <Withdraw generalData={generalData} />
        

      </div>
    </div>
  );
};
export default StabHandle;
