import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import '../../css/game/private.nft.css'
import ImgS3 from "../../components/ImgS3";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function RocketP() {
  const [sellHuman, setSellHuman] = useState(0)
  const [sellRocket, setSellRocket] = useState(0)
  const [listR, setListR] = useState([]);
  const [isCheck, setIsCheck] = useState([]);


  const presaleRocket = [
    {
      id: 1,
      name: "ROCKET 1",
      seat: 2,
      href: "#",
      box: 3500,
      imageSrc: "/images/private/spaceb1.png",
      imageAlt: "Front of men's Basic Tee in black.",
      price: 16,
      level: "Robot",
      remainingFee: 0
    },
    {
      id: 2,
      name: "ROCKET 2",
      seat: 4,
      href: "#",
      box: 3500,
      imageSrc: "/images/private/spaceb2.png",
      imageAlt: "Front of men's Basic Tee in black.",
      price: 30,
      level: "Chief",
      remainingFee: 0
    },
    {
      id: 3,
      name: "ROCKET 3",
      seat: 6,
      href: "#",
      box: 3000,
      imageSrc: "/images/private/spaceb3.png",
      imageAlt: "Front of men's Basic Tee in black.",
      price: 100,
      level: "Engineer",
      remainingFee: 0
    },
    {
      id: 4,
      name: "ROCKET 4",
      seat: 8,
      href: "#",
      box: 2600,
      imageSrc: "/images/private/spaceb4.png",
      imageAlt: "Front of men's Basic Tee in black.",
      price: 200,
      level: "Academic",
      remainingFee: 0
    },
    {
      id: 5,
      name: "ROCKET 5",
      seat: 8,
      href: "#",
      box: 1800,
      imageSrc: "/images/private/spaceb5.png",
      imageAlt: "Front of men's Basic Tee in black.",
      price: 250,
      level: "Admin",
      remainingFee: 0
    },
  ];

  useEffect(() => {
    setListR(presaleRocket);
  }, [listR]);


  const handleClick = (e) => {
    const { id, checked, } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <>
      <div className="mx-10vw">
        <div className="mx-auto max-w-6xl mt-10 ">
        <div className="home-section mystery-content">
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <div className="section-tlt">
                <center style={{alignContent: 'center !important',alignItems: 'center'}} className="tlt text-4xl mb-4">SPACESHIPS BOXES</center>
                <p className="para">Acquire the first rockets and start exploring the nearest or farthest planets to earn USD. Good luck!.</p>
              </div>
            </div>
          </div>
          <div className="row boxs-ls">
            <div className="w-full pt-10 z-10">
              <center>
                {listR.map((rocket,index) => {
                  return (
                    <div index={index} key={index} className="inline-block w-full md:w-1/2 my-10 z-9999 text-center px-2 py-2 text-white z-10">
                    <div className="flex flex-col items-center justify-center mx-auto bg-color2 rounded-2xl mx-2 shadow-lg" style={{maxWidth: '320px'}}>
                      <ImgS3 src={rocket.imageSrc} alt="Contains 3 random NFTs" className="h-80 object-contain" />
                      <p className="text-primary mb-1 mr-4 px-6 -mt-1 py-1 bg-gray-700 rounded-full">{rocket.box}/{rocket.box}</p>
                      <div className="w-full p-4">
                        <div className="flex flex-col items-start justify-center bg-color1 p-5 rounded-2xl w-full text-left">
                          <span className="my-1">Spaceships Box level {rocket.id}</span><span className="my-1 text-colorGreen">{rocket.price}$<span className="ml-2 text-white">(BUSD)</span></span>
                          <div>
                          <input
                                type="number"
                                name="number"
                                id="number"
                                min="1"
                                max="9"
                                required
                                
                                onChange={handleClick}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-800 mb-3 px-4"
                                placeholder="0"
                              />
                            </div>
                          
                          <div className="flex justify-center items-center w-full mt-3">
                            <button className="bn632-hover bn25 btn7"><span style={{fontFamily: 'Titles, sans-serif', letterSpacing: '0.5pt'}}>Buy</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )
                })}
              </center></div>
          </div>
        </div>
      </div>
        </div>
      </div>
    </>
  );
}

export default RocketP;
