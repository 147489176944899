import React from "react";
import "../../css/game/wactus.css";
import "../../css/game/inventory.css";
import { Tab } from "@headlessui/react";
import Human from "./Human";
import Rocket from "./Rocket";
import LevelUp from "./LevelUp/LevelUp";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function InventoryScreen() {
  const categories = {
    Crew: [
      {
        id: 1,
        component: <Human />,
      },
    ],
    Ship: [
      {
        id: 1,
        component: <Rocket />,
      },
    ],
  };

  return (
    <div id="hakkv " className="bg-cover bg-center" >
      <div className="inventory-content News">
        <div vphawk className="relative__hb hw-main">
          <div vphawk className="inventory_nft">
            <div vphawk className="relative__hb">
              <div vphawk className="inventory-assets">
                <div className="head_ivt">
                  <div className="mt-28">
                    <Tab.Group>
                      <Tab.List className="flex justify-center">
                        {Object.keys(categories).map((category) => (
                          <Tab
                            key={category}
                            className={({ selected }) =>
                              classNames(
                                "tab",
                                selected
                                  ? "bg-purple-900  text-white"
                                  : "bg-[#502c92] text-slate-200 opacity-80"
                              )
                            }
                          >
                            {category}
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels className="">
                        {Object.values(categories).map((posts, idx) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Tab.Panel key={idx}>
                            {posts.map((post, i) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <div className="" key={i}>
                                {post.component}
                              </div>
                            ))}
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryScreen;
