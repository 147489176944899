/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from "react";
import PROVIDER from "../hooks/provider";
//import {useHuman}  from '../hooks/useContracts';
import Web3Context from "./Web3Context";
import { UseHuman, UseGame, useToken,address,useBuyHumanFactory} from "../hooks/useContracts.js";
import { BigNumber, constants, ethers } from "ethers";

const TonkenContext = createContext();

const TokenProvider = ({ children }) => {
  const { accounts, isLoaded, setupdate, update,etherJSProvider } = useContext(Web3Context);  
  const [update_, setupdate_] = useState(0);
  const [balanceOf, setbalanceOf_] = useState(BigNumber.from('0'))
  const [gameupdate, setgameupdate] = useState(0)
  const [isApprove, setisApprove] = useState(true)
  const [isApproveFuse, setisApproveFuse] = useState(false)
  const [isApproveBuy, setisApproveBuy] = useState(false)
  const [isApproveNftFuse, setisApproveNftFuse] = useState(false)
  const [buylenght, setbuylenght] = useState([0,0]);

  
  const Human = UseHuman();
  const Token = useToken();
  const BuyHumanFactory=useBuyHumanFactory()

  const gameupdateHandle =()=>{
    setgameupdate(gameupdate +1)
  }
useEffect(() => {
  if(!isLoaded)
  return
    // buylenghtHandle()
    balanceOfHandle()
    gameAllowanceHandle()
    // fuseAllowanceHandle()
    buyAllowanceHandle()
  
  return () => {
  
  }
}, [accounts,isLoaded,gameupdate]) 

useEffect(() => {
  if(!isLoaded)
  return
  console.log("paso");
  //gameApproveHandle()
  return () => {
  
  }
}, [isApprove])

  

  const balanceOfHandle = async()=>{    
    if (!isLoaded && accounts!='000000000000000000000000000000000000000000000')
    return
    const [load, contract] = await Token;  
     
    let  balance_ = await contract.balanceOf(accounts)
    console.log(balance_);
    balance_=ethers.utils.formatEther(balance_).split(".").map((value,i)=>{
      if(value[0] === undefined) return ""
      if(i==1)
      return //`${value[0]}${value[1]}${value[1]}`
      else return value
    }).join()    
    
    setbalanceOf_(balance_)
  }

  const gameApproveHandle = async(type)=>{    
    if (!isLoaded && accounts!='000000000000000000000000000000000000000000000')
    return
    const [load, contract] = await Token;   
    const addr=type==undefined?address.game:address.fuse
    const res = await contract.approve(addr,constants.MaxUint256)
    res.wait().then(()=>setgameupdate(gameupdate +1))
  }

  const fuseApproveHandle = async()=>{    
    if (!isLoaded && accounts!='000000000000000000000000000000000000000000000')
    return
    console.log("paso");
    
    const [load, contract] = await Human;   
    const addr=address.fuse
   const res =  await contract.setApprovalForAll(addr,true)
    res.wait().then(()=>setgameupdate(gameupdate +1))
  }
  
  const fuseAllowanceHandle = async()=>{    
    if (!isLoaded && accounts!='000000000000000000000000000000000000000000000')
    return
    const [load, contract] = await Human;   
    const addr=address.fuse
    const res = await contract.isApprovedForAll(accounts,addr)    
    setisApproveNftFuse(res)
  }


  

  const buylenghtHandle = async()=>{    
    if (!isLoaded && accounts!='000000000000000000000000000000000000000000000')
    return
    const [load, contract] = await BuyHumanFactory;       
    const res = await contract.length(0)   
    const res1 = await contract.length(1)   
    setbuylenght([res.toString(),res1.toString()])    
  }
  const buyApproveHandle = async()=>{    
    if (!isLoaded && accounts!='000000000000000000000000000000000000000000000')
    return
    console.log("paso");
    
    const [load, contract] = await Token;   
    const addr=address.game
   const res =  await contract.approve(addr,ethers.constants.MaxUint256)
    res.wait().then(()=>setgameupdate(gameupdate +1))
  }
  const buyAllowanceHandle = async()=>{    
    if (!isLoaded && accounts!='000000000000000000000000000000000000000000000')
    return
    const [load, contract] = await Token;   
    const addr=address.game
    const res = await contract.allowance(accounts,addr)   
    //console.log(res.gt(constants.MaxUint256.div(5),"ressssssssssssssssssssssss"));     
    setisApproveBuy(res.gt(constants.MaxUint256.div(5)))
  }
  const gameAllowanceHandle = async()=>{
    if (!isLoaded && accounts!='000000000000000000000000000000000000000000000')
    return
    const [load, contract] = await Token;   
    const allowance_ = await contract.allowance(accounts,address.game)    
    const allowancefuse_ = await contract.allowance(accounts,address.fuse)        
    
    
    setisApproveFuse(allowance_.gt(constants.MaxUint256.div(5)))    
    console.log(allowance_,"allowancefuse_allowancefuse_allowancefuse_allowancefuse_allowancefuse_allowancefuse_allowancefuse_allowancefuse_allowancefuse_");
    setisApprove(allowance_.gt(constants.MaxUint256.div(5)))
  }
  useEffect(() => {
    if (isLoaded) {
      setTimeout(async () => {
        console.log("###################################");        
      });
    }
    return () => {};
  }, [isLoaded, accounts, update_]);



  const datas = {
    fuseApproveHandle,
    gameApproveHandle,
    balanceOf,
    isApprove,
    isApproveFuse,
    isApproveNftFuse,
    gameupdateHandle,
    buyApproveHandle,
    isApproveBuy,
    buylenght
  };

  return (
    <TonkenContext.Provider value={datas}>
      {children}
    </TonkenContext.Provider>
  );
};

export { TokenProvider };
export default TonkenContext;
