/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from "react";
import { level, mockUp, ship, ship_, peopleBase } from "./data";
//import {useHuman}  from '../hooks/useContracts';
import Web3Context from "./Web3Context";
import { UseHuman, UseGame } from "../hooks/useContracts.js";
import { BigNumber, Contract, ethers, utils } from "ethers";
import { DateTime } from "luxon";

const InventoryContext = createContext();
const InventoryProvider = ({ children }) => {
  const { accounts, isLoaded, setupdate, update, etherJSProvider } =
    useContext(Web3Context);
  const [people, setPeople] = useState([]);
  const [spaceShip, setSpaceShip] = useState(ship);
  const [expSelect, setexpSelect] = useState(0);
  const [update_, setupdate_] = useState(0);
  const [play_, setplay] = useState(0);
  const [generalData, setgeneralData] = useState({
    timestamp: 0,
    seat: 0,
    bossSeat: 0,
    expTotal: 0,
    totalHuman: 0,
    totalSpaceShip: 0,
    isDead: [],
    feePays: [],
    reward: 0,
    wins: 0,
    loser: 0,
    humanavailable:0,
    expAvailable:0,
    rocketAvailable:0,
    seatAvailable:0,
    lastWindra: 0,
    lastPlay:0,
    canplay:true,
    daysToZeroTax:0,
    tax:0
  });
  const Human = UseHuman();
  const Game = UseGame();

  const playHandle = () => {
    setplay(play_ + 1);
  };
  const updateHandle = () => {
    setupdate_(update_ + 1);
  };

  const getAllNftspaceShip = async () => {
    const [load, contract] = await Human;
    const allTokens = await contract.getAllNFTOwnerByType(accounts, 1);
    //console.log(allTokens);
    let seat_ = 0;
    let bossSeat_ = 0;
    const provider = await etherJSProvider();
    const timestamp = BigNumber.from((await provider.getBlock(-1)).timestamp);

    const day30 = BigNumber.from("86400").mul("30");
    const day15 = BigNumber.from("86400").mul("15");
    const humanDaysForPayFeesArray = [day30, day30, day30, day15, day15];
    let rocketAvailable =0  
    let seatAvailable =0  

    const tokenLevel = (await contract.getNftBatch(allTokens)).map(
      (value, i) => {
        seat_ += value.seat.toNumber();
        if (value[0].toNumber() == 4) bossSeat_ += 1;
        const isFeePays = timestamp
          .sub(value.lastDate)
          .gte(humanDaysForPayFeesArray[value.level.toNumber() - 1]);
          if(!isFeePays){
        rocketAvailable++
        seatAvailable+=value.seat.toNumber();

          }
          const _level = value[0].toNumber()
          const feeDays = [30, 30, 30, 15, 15];
          let newTime = 0;
          let _newTime;
          if(_level > 0 && _level != undefined && _level < 5) {
            _newTime = DateTime.fromSeconds(Number(value.lastDate)).plus({
            day: feeDays[_level - 1],
          });
          const time_ = DateTime.now()
          const dif =  _newTime - time_
          if (dif > 0) {
            newTime = _newTime.diff(time_, ["days"]).toObject().days.toFixed(0);
          }
          }

        return {
          tokenID: allTokens[i].toNumber(),
          id: _level,
          name: value[0].toNumber(),
          level: value.level.toNumber(),
          class: ship_[value[0].toNumber()].class,
          exhausted: isFeePays,
          usage: value[5].toNumber(),
          imageUrl: ship_[value[0].toNumber()].imageUrl,
          previewUrl: ship_[value[0].toNumber()].previewUrl,
          videoRocketUrl: ship_[value[0].toNumber()].videoRocketUrl,
          RocketImage: ship_[value[0].toNumber()].RocketImage,
          title: "Paradigm Representative",
          role: "Admin",
          seat: value.seat.toNumber(),
          lastDate:value.lastDate.toString(),
          remainingFee: newTime,
        };
      }
    );

    setgeneralData(
      Object.assign(generalData, {
        seat: seat_,
        bossSeat: bossSeat_,
        totalSpaceShip: allTokens.length,
        rocketAvailable,
        seatAvailable
      })
    );

    setSpaceShip(tokenLevel);
  };

  const getUserData = async () => {
    const [_, contract2] = await Game;
    const userData = await contract2.users(accounts);
    // console.log(userData, "DataOne");
    // console.log(
      // {
        // win: userData.wins.toString(),
        // lose: userData.loser.toString(),
        // reww: userData.reward.toString(),
        // lastWindra: userData.lastWithDraw.toString(),
        // lastPlay:userData.lastPlay.toString()
      // },
      // "UserData"
    // );
    const [_load, contract3] = await Human;
    const _nextPlay = await contract3.blockWalletUntil(accounts);
    const getDate = await contract2.getDate();
    const _canplay = getDate >= _nextPlay;
    const day15 = BigNumber.from("86400").mul("15");
    const nextWithDrawZero = BigNumber.from(userData.lastWithDraw).add(day15);

    let _daysToZeroTax = 0;
    let _tax = 0;
    console.log(nextWithDrawZero, getDate, "nextWithDrawZero");
    if(nextWithDrawZero > getDate) {
      _daysToZeroTax = ((nextWithDrawZero.sub(getDate)).toNumber() / 86400).toFixed(2);
      _tax = 30;
    }

    // console.log(_canplay, "canplay");
    setgeneralData(
      Object.assign(generalData, {
        reward: utils
          .formatEther(userData.reward.toString())
          .split(".")
          .map((value, i) => {
            const value1 = value[0] == undefined ? "" : value[0];
            const value2 = value[1] == undefined ? "" : value[1];
            const value3 = value[2] == undefined ? "" : value[2];
            const value4 = value[3] == undefined ? "" : value[3];
            if (i == 1) return `${value1}${value2}${value3}${value4}`;
            return value;
          })
          .join(),
        wins: userData.wins.toString(),
        loser: userData.loser.toString(),
        lastWindra: userData.lastWithDraw.toString(),
        lastPlay:userData.lastPlay.toString(),
        canplay:_canplay,
        daysToZeroTax: _daysToZeroTax,
        tax: _tax,
      })
    );
  };

  const getAllNftHuman = async () => {
    const [load, contract] = await Human;
    // console.log(process.env.REACT_APP_S3_URL);
    const allTokens = await contract.getAllNFTOwnerByType(accounts, 0);
    console.log(contract.address, "contract.address");
    const provider = await etherJSProvider();
    const timestamp = BigNumber.from((await provider.getBlock(-1)).timestamp);
    let exp_ = 0;
    const day30 = BigNumber.from("86400").mul("30");
    const day15 = BigNumber.from("86400").mul("15");
    const humanDaysForPayFeesArray = [day30, day30, day30, day15, day15];
    let humanavailable =0
    let expAvailable =0
    const tokenLevel = (await contract.getNftBatch(allTokens)).map(
      (value, i) => {
        exp_ += value.exp.toNumber();
        
        const isFeePays = timestamp
          .sub(value.lastDate)
          .gte(humanDaysForPayFeesArray[value.level.toNumber() - 1]);
        const isDead_ = timestamp.sub(value.mintDate).gte(value.maxAge);
        if(isDead_ || isFeePays)
        humanavailable +=1
        else
        expAvailable += value.exp.toNumber();
        const _level = value[0].toNumber()
        const feeDays = [30, 30, 30, 15, 15];
        let newTime = 0;
        let _newTime;
        if(_level > 0 && _level != undefined && _level <= 5) {
          _newTime = DateTime.fromSeconds(Number(value.lastDate)).plus({
          day: feeDays[_level - 1],
        });
        const time_ = DateTime.now()
        const dif =  _newTime - time_
        if (dif > 0) {
          newTime = _newTime.diff(time_, ["days"]).toObject().days.toFixed(0);
        }
        }
        return {
          tokenID: allTokens[i].toNumber(),
          id: _level,
          name: mockUp[value[0].toNumber()].generyName,
          level: mockUp[value[0].toNumber()].name,
          exhausted: isDead_ ? false : isFeePays,
          dead: isDead_,
          mintDate:value.mintDate,
          maxAge:value.maxAge,          
          imageUrl: mockUp[value[0].toNumber()].imageUrl,
          imageIcoUrl: mockUp[value[0].toNumber()].imageIcoUrl,
          videoUrl: mockUp[value[0].toNumber()].videoUrl,
          previewUrl: mockUp[value[0].toNumber()].previewUrl,
          exp: value[1].toNumber(),
          lastDate:value.lastDate,
          remainingFee: newTime
          //level:
          //exp:value[1].toNumber(),
          //mintDate:value[2].toNumber(),
          //seat:value[3].toNumber(),
          //lastDate:value[4].toNumber(),
          //maxAge:value[5].toNumber()
        };
      }
    );
    // console.log(tokenLevel);
    setgeneralData(
      Object.assign(generalData, {
        expTotal: exp_,
        totalHuman: allTokens.length,
        timestamp,
        humanavailable:allTokens.length - humanavailable,
        expAvailable
      })
    );
    setPeople(
      tokenLevel
    //   tokenLevel.sort((a, b) => {
    //     if (!a.dead && a.exhausted) return -1;
    //     if (!a.dead && !a.exhausted) return -1;
    //     else return 1;
    //     return 0;
    //   })
    );
  };

  useEffect(() => {
    if (isLoaded) {
      setTimeout(async () => {
        await getUserData();
      });
    }
    return () => {};
  }, [play_, isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      setTimeout(async () => {
        await getAllNftHuman();
        await getAllNftspaceShip();
      });
    }
    return () => {};
  }, [isLoaded, accounts, update_]);

  useEffect(() => {
    if (isLoaded) console.log(people);
    return () => {};
  }, [isLoaded]);

  const datas = {
    people,
    spaceShip,
    generalData,
    expSelect,
    setexpSelect,
    updateHandle,
    playHandle,
  };

  return (
    <InventoryContext.Provider value={datas}>
      {children}
    </InventoryContext.Provider>
  );
};

export { InventoryProvider };
export default InventoryContext;
