import React from "react";
import "../../css/game/wactus.css";
import "../../css/game/inventory.css";
import { Tab } from "@headlessui/react";
import HumanP from "./HumanP";
import RocketP from "./RocketP";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function InventoryScreen() {
  const categories = {
    Human: [
      {
        id: 1,
        component: <HumanP />,
      },
    ],
    Rocket: [
      {
        id: 1,
        component: <RocketP />,
      },
    ],

  };

  return (
    <div id="hakkv" className="">
      <div className="inventory-content">
        <div vphawk className="relative__hb hw-main">
          <div vphawk className="inventory_nft">
            <div vphawk className="relative__hb">
              <div vphawk className="inventory-assets">
                <div className="head_ivt">
                  <div className="mt-28">
                    <Tab.Group>
                      <Tab.List className="flex justify-center">
                        {Object.keys(categories).map((category,i) => (
                          <Tab
                            key={i}
                            className={({ selected }) =>
                              classNames(
                                "tab",
                                selected
                                  ? "bg-[#46567a]  text-white"
                                  : "bg-[#252c38] text-gray-400"
                              )
                            }
                          >
                            {category}
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels className="">
                        {Object.values(categories).map((posts, idx) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Tab.Panel key={idx}>
                            {posts.map((post, i) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <div className="" key={i}>
                                {post.component}
                              </div>
                            ))}
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryScreen;
