import React from "react";
import "./App.css";
import AppRouter from "./routers/AppRouter";

function App() {
 
  return <AppRouter />;
}

export default App;
