/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState, useEffect, useContext } from "react";
import { useToasts } from "react-toast-notifications";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import InventoryContext from "../../context/Inventory";
import Web3Context from "../../context/Web3Context";
import { UseHuman, UseGame } from "../../hooks/useContracts";
import UseCheckBook from "../../hooks/UseCheckBook";
import Checkbox from "../../components/Checkbox";
import { constants, BigNumber, ethers } from "ethers";
import ImgS3 from "../../components/ImgS3";
import { PeopleList } from "./SlidePlayHandle";
import ImgS3Ximbia from "../../components/ImgS3Ximbia";


export default function SlidePlay({ planet, status,setStatus,notification,setNotification,isStatus,setIsStatus }) {
  const { addToast } = useToasts();
  const [state, checkedBoxHandle, resetCheckedBoxHandle] = UseCheckBook();
  const Human = UseHuman();
  const Game = UseGame();
  const { accounts, isLoaded, setupdate, update, etherJSProvider } =
  useContext(Web3Context);
  const {
    expSelect,
    setexpSelect,
    people,
    generalData,
    update_,
    updateHandle,
    playHandle
  } = useContext(InventoryContext);
  const [open1, setOpen1] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isavailable, setIsavailable] = useState(false);
  const [_people, setpeople_] = useState(people);
  const [win, setwin] = useState({ win: false, update: 0 });
  const [bonus, setBonus] = useState(0);


  useEffect(() => {
    if (!isLoaded) return;
    const planetExp = planet.exp;
    const totalExp = generalData.expAvailable;
    let people_ = people;
    people_ = people.filter((values) => !values.dead && !values.exhausted);
    setpeople_(people_);
    setIsavailable(totalExp >= planetExp);
    return () => {};
  }, [generalData, accounts, isLoaded, update_, people]);

  useEffect(() => {
    handleBonus();
  }, [state]);

  const playGame = async (e) => {
    try {
      const [load, contract] = await Game;
      console.log("Play");
      const passenger_ = Array.from(state);


      console.log(passenger_,planet.id);
      
      let estimateGas = await contract.estimateGas.playGame(
        passenger_,
        planet.id
      );
      
      estimateGas = estimateGas.add(
        estimateGas.mul(BigNumber.from("10")).div("100")
      );     

      const res = await contract.playGame(passenger_,planet.id , {
        gasLimit: estimateGas,
      });
      addToast("playGame success", { appearance: "success" });
      setNotification("successfully");
      res.wait().then((value) => {
        let tes;
        value.events.map((value) => {
          if (value.event == "GameOver") {
            tes = value.args;
            setNotification("GameOver");
          }
        });

        setIsStatus(ethers.utils.formatEther(tes.reward));
        if (tes.win == undefined) return;

        console.log({
          win: tes.win, //bool
          reward: tes.reward.toString(), //number
          tes,
        });
        if (tes.win === true) {
          setNotification("correct");          
        }
        setStatus(tes);
        playHandle();
      });
    } catch (err) {
      console.log(err.data.message)
      addToast(err.data.message, { appearance: "error" });
    }
  };

  async function handleBonus() {
    setBonus(0);
    const passenger_ = Array.from(state);
    let arrayBonus = [0,0,0,0,0];
    const [_, contract] = await Human;
    if(!_) {
      console.log("Error: Human contract not loaded");
      return 0;
    }

    for (let i = 0; i < passenger_.length; i++) {
      const _filter = _people.filter((value_) => {
      if(value_.tokenID == passenger_[i]) {
        return true;
      }
      return false;
      });
      const person = _filter[0];

      console.log(person, passenger_[i], "person");

      const _level = person.id;
      if(_level > 0 && _level < 6) {
        arrayBonus[_level - 1] += 1;
      }
    }
    let _bonus = 0;
    if(arrayBonus[0] > 0) {
      const [load, contract2] = await Game;
      if(!load) {
        console.log("murio 2 bonus");
        return 0;
      }
      console.log("llamada 2");
      _bonus = await contract2.bonusHandle(arrayBonus);
      _bonus = BigNumber.from(_bonus).toNumber() / 10;
      _bonus = _bonus.toFixed(1);
    }
    console.log(_bonus, "bonus");
    console.log(arrayBonus, "arrayBonus");
    setBonus(_bonus);
    return _bonus;
  }

  const handleSelectAll = (e) => {
    if (isCheckAll) {
      setexpSelect(0);
      resetCheckedBoxHandle();
      return setIsCheckAll(false);
    }
    let  people_ = _people
    
    people_.sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      }).reverse();
    let exp_ = 0;
    let human = 0;

    setpeople_(
      people_.filter((value) => {
        if (exp_ >= planet.exp) return true;
        checkedBoxHandle(true, value.tokenID);
        exp_ += value.exp;
        setexpSelect(expSelect + exp_);
        if(value.id!=4)
        human++;
        return true;
      })
    );
    if (human > generalData.seat) {
      resetCheckedBoxHandle();
      setIsCheckAll(false);
      return addToast("insufficient space ships", { appearance: "error" });
    }
    setIsCheckAll(true);
  };

  const handleClick = (e, value_, exp, id, exhausted) => {
    console.log(id, "id seleccionado");
    if (exhausted)
      return addToast("need payment feede", { appearance: "error" });


    checkedBoxHandle(e.target.checked, value_, planet.id);
    if (e.target.checked) setexpSelect(expSelect + exp);
    else setexpSelect(expSelect - exp);

  };


  return (
    <>
      <div className="start-sc">
        <button
          className="st-badge st-light btn1"
          onClick={() => {

            setexpSelect(0);
            setOpen1(true);
          }}
          title="cmd+k OR ctrl+k"
        >
          Start scan
        </button>
      </div>
      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={setOpen1}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md mt-12">
                  <div className="h-full flex flex-col bg-primary shadow-xl overflow-y-scroll">
                    <div className="px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <h2
                          id="slide-over-heading"
                          className="text-lg font-medium text-white"
                        >
                          Scan
                        </h2>
                        <div className="ml-3 h-7 mt-7 flex items-end">
                          <button
                            type="button"
                            className="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => setOpen1(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Main */}
                    <div>
                      <div className="pb-1 sm:pb-6">
                        <div>
                          <div className="relative h-40 sm:h-56">
                            <ImgS3Ximbia
                              className="absolute h-full w-full object-cover"
                              src={planet.img}
                              alt=""
                            />
                          </div>
                          <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                            <div className="sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="font-bold text-xl text-hero-gradient sm:text-2xl">
                                    {planet.title}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                        <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                          <div>
                            <div className="py-3 flex justify-between items-center">
                              <h3 className="font-medium text-white mb-2">
                                Crew
                              </h3>
                              <Checkbox
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                className={`focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded`}
                                handleClick={handleSelectAll}
                                isChecked={isCheckAll}
                              />
                            </div>

                            <ul className=" border-t border-b border-gray-200 divide-y divide-gray-200 overflow-y-scroll h-40">
                            <PeopleList handleClick ={handleClick} peoples={_people} state={state}/>
                            </ul>
                          </div>
                          
                          <div>
                            <dt className="text-sm font-medium text-gray-700 sm:w-40 sm:flex-shrink-0">
                              Winrate
                            </dt>
                            <dd className="mt-1 text-sm text-gray-800 sm:col-span-2">
                              {planet.winrate + (planet.winrate * bonus / 100) > 90 ? 90 : planet.winrate + (planet.winrate * bonus / 100)} % (bonus: {bonus}%)
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-700 sm:w-40 sm:flex-shrink-0">
                              Required EXP
                            </dt>
                            <dd className="mt-1 text-sm text-gray-800 sm:col-span-2">
                              {planet.exp}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-700 sm:w-40 sm:flex-shrink-0">
                              Earn
                            </dt>
                            <dd className="mt-1 text-sm text-gray-200 sm:col-span-2">
                              <p>{planet.earn} USD</p>
                            </dd>
                          </div>
                          <div>
                            <dt className="text-sm font-medium text-gray-700 sm:w-40 sm:flex-shrink-0">
                              Note:
                            </dt>
                            <dd className="mt-1 text-sm text-gray-200 sm:col-span-2">
                              <p>10% of oil will be charged in XMB at the time of performing the scan</p>
                            </dd>
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              playGame();
                            }}
                            className=" px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            START
                          </button>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
